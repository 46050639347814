import { Button, Card, Col, Icon, Input, message, Row, Spin, Table } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Highlighter from "react-highlight-words";
import connect from "react-redux/es/connect/connect";
import { empty } from "../../shared/helpers/generalHelper";
import { DEFAULT_CURRENCY_SIGN } from "../../shared/utils/constants";
import { posOperations } from "../../state/ducks/pos";
import styles from "./LocationList.module.scss";

class LocationList extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.initialState = {
            formLoading: false,
            locations: []
        };

        this.state = { ...this.initialState };
        this.currencyCode = this.props.posConfig.currency_code || DEFAULT_CURRENCY_SIGN;
    }

    componentDidMount() {
        this.loadLocations();
    }

    loadLocations = () => {
        this.setState({ formLoading: true });

        this.setState(
            {
                locations: [
                    {
                        id: 1,
                        location_name: 'Ruang XXX',
                        parent_emr_location: '',
                        parent_emr_organization: 'Org XXX',
                    }
                ]
            },
            () => {
                this.setState({ formLoading: false });
            }
        );

        // apiCall(
        //     endpoint.GET_ACCOUNT_RECEIVABLE_LIST,
        //     "get",
        //     null,
        //     result => {
        //         this.setState(
        //             {
        //                 locations: result.data
        //             },
        //             () => {
        //                 this.setState({ formLoading: false });
        //             }
        //         );
        //     },
        //     error => {
        //         if (error === "timeout") {
        //             // Network is not available
        //             message.error(
        //                 "Telah terjadi kegagalan koneksi, silakan coba lagi."
        //             );
        //         } else {
        //             // API throwing error object
        //             message.error(error.data.error.message);
        //         }
        //         this.setState({ formLoading: false });
        //     }
        // );
    };

    customFilterProps = (dataIndex, dataLabel, customRender = null) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataLabel}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => {
            let modifiedText = text || '';
            if(!empty(customRender)){
                modifiedText = customRender(text);
            }
            return (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={modifiedText}
                />
            );
        },
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    render() {
        const {
            formLoading, locations
        } = this.state;

        const locationColumns = [
            {
                title: "Nama Lokasi",
                dataIndex: "location_name",
                key: "location_name",
                sorter: (a, b) => a.location_name.length - b.location_name.length,
                ...this.customFilterProps('location_name', 'Nama Lokasi')
            },
            {
                title: "Lokasi Induk",
                dataIndex: "parent_emr_location",
                key: "parent_emr_location",
                sorter: (a, b) => a.parent_emr_location.length - b.parent_emr_location.length,
                ...this.customFilterProps('parent_emr_location', 'Bagian Dari')
            },
            {
                title: "Organisasi Induk",
                dataIndex: "parent_emr_organization",
                key: "parent_emr_organization",
                sorter: (a, b) => a.parent_emr_organization.length - b.parent_emr_organization.length,
                ...this.customFilterProps('parent_emr_organization', 'Bagian Dari')
            },
            {
                title: "Action",
                key: "action",
                render: (text, record) => (
                    <span>
                        <Button size="small"
                                type="primary"
                                htmlType="button"
                                style={{marginRight: 5}}
                                onClick={() => {
                                    message.info('Edited');
                                }}>Edit</Button>

                        <Button size="small"
                                type="danger"
                                htmlType="button"
                                onClick={() => {
                                    message.info('Deleted');
                                }}>Delete</Button>
                    </span>
                )
            }
        ];

        const pageTitle = (
            <React.Fragment>
                Daftar Lokasi
            </React.Fragment>
        );

        return (
            <Card title={pageTitle} bordered={false}>
                <Row gutter={48}>
                    <Col xxl={24} sm={24}>
                        <Spin spinning={formLoading}>
                            <div style={{marginBottom: 10, textAlign: 'right'}}>
                                <Button type={"primary"} onClick={() => {
                                    this.props.history.push("/emr/location/new");
                                }}>Tambah Lokasi</Button>
                            </div>
                            <div className={styles.formItem}>
                                <Table rowKey="trx_id" columns={locationColumns} dataSource={locations} pagination={false}/>
                            </div>
                        </Spin>
                    </Col>
                </Row>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        posConfig: state.authState.userData
    };
};

const mapDispatchToProps = {
    setNewLocalTrx: posOperations.setNewLocalTrx
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LocationList);