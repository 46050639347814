import { Button, Card, Col, Form, Icon, Input, List, message, Modal, Radio, Row, Select, Spin, Table, Tabs } from "antd";
import TextArea from "antd/lib/input/TextArea";
import debounce from "lodash/debounce";
import moment from "moment";
import React, { Component } from "react";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import QrReader from "react-qr-reader";
import connect from "react-redux/es/connect/connect";
import { Link } from "react-router-dom";
import uuid from "uuid/v4";
import PerencanaanPulang from "../modules/CustomerJournal/PerencanaanPulang";
import PersetujuanMedis from "../modules/CustomerJournal/PersetujuanMedis";
import RekamMedisTubuh from "../modules/CustomerJournal/RekamMedisTubuh";
import RiwayatAlergi from "../modules/CustomerJournal/RiwayatAlergi";
import ScreeningRawatJalan from "../modules/CustomerJournal/ScreeningRawatJalan";
import ErrorHandler from "../shared/classes/ErrorHandler";
import { empty } from "../shared/helpers/generalHelper";
import { apiCall } from "../shared/helpers/networkHelper";
import { nl2brReact, validEmail, validPhone } from "../shared/helpers/stringHelper";
import { RED } from "../shared/utils/colors";
import { MOMENT_READABLE_DATE_FORMAT, MOMENT_SQL_DATE_FORMAT, MOMENT_SQL_DATETIME_FORMAT } from "../shared/utils/constants";
import * as endpoint from "../shared/utils/endpoints";
import styles from "./CustomerJournal.module.scss";

const FormItem = Form.Item;

class CustomerJournal extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            step: 1,
            user_identifier_type: this.props.match?.params?.user_identifier_type || "phone",
            user_identifier_string: this.props.match?.params?.user_identifier_string || "",
            user: null,
            journalFilter: "",
            local_trx_id: "",
            activityPhotos: [],
            activityPhoto: null,
            activityPhotoDesc: "",
            activityNotes: "",
            activityNotesForCashier: "",
            takePhotoModalOpened: false,
            searchUserLoading: false,
            customerActivityFormLoading: false,
            userSearchResult: [],
            newActivityPhotoFormVisible: false,
            chosenActivity: null,
            pos_area_id: "",
            soap_subject: "",
            soap_object: "",
            soap_assessment: "",
            soap_planning: "",
            journalNotes: "",
            journalNotesInitial: "",
            journalNotesModifiedBy: "",
            journalNotesModifiedAt: "",
            retrievedUserData: null,
            retrievedMemberData: null,
            areas: [],
            icd10Items: [],
            icd_10_id: null,
            emrData: null
        };

        this.initialActivityFormState = {
            isEditActivity: false,
            activityPhotos: [],
            activityPhoto: null,
            activityPhotoDesc: "",
            activityNotes: "",
            activityNotesForCashier: "",
            pos_area_id: "",
            icd_10_id: null,
            soap_subject: "",
            soap_object: "",
            soap_assessment: "",
            soap_planning: "",
            journalNotes: "",
            retrievedUserData: null,
            retrievedMemberData: null
        };

        this.state = { ...this.initialState };
    }

    componentDidMount() {
        if (!empty(this.props.match?.params?.user_identifier_type) && !empty(this.props.match?.params?.user_identifier_string)) {
            this.onSearchCustomer();
        }

        apiCall(
            endpoint.GET_POS_AREAS,
            "get",
            null,
            result => {
                this.setState({
                    areas: result.data
                });
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            }
        );

        window.scrollTo(0, 0);
    }

    onChangeUserIdentifierType = e => {
        this.setState({
            user_identifier_type: e.target.value,
            user_identifier_string: ""
        });
    };

    onChangeUserIdentifierString = e => {
        this.setState({
            user_identifier_string: e.target.value
        });
    };

    onSearchCustomer = e => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (
            empty(this.state.user_identifier_string) ||
            empty(this.state.user_identifier_type)
        ) {
            message.error("Please fill out the customer contact.");
            return;
        }

        if (this.state.user_identifier_type === "phone") {
            if (!validPhone(this.state.user_identifier_string)) {
                message.error("Please input a valid phone number.");
                return;
            }
        }

        if (this.state.user_identifier_type === "email") {
            if (!validEmail(this.state.user_identifier_string)) {
                message.error("Please input a valid email.");
                return;
            }
        }

        this.setSearchUserLoading(true);

        apiCall(
            endpoint.GET_CUSTOMER_ACTIVITIES + `?user_identifier_type=${this.state.user_identifier_type}&user_identifier_string=${this.state.user_identifier_string}`,
            "get",
            null,
            result => {
                this.setState({
                    step: 2,
                    user: result.data
                }, () => {
                    this.getUserInfo();
                    this.getEmrData(result.data.user_code);
                });                
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            () => {
                this.setSearchUserLoading(false);
            }
        );
        
    };

    setSearchUserLoading = state => {
        this.setState({
            searchUserLoading: state
        });
    };

    onSearchCustomerByKeyword = (val, e) => {
        e.stopPropagation();
        e.preventDefault();

        if (
            empty(this.state.user_identifier_string) ||
            empty(this.state.user_identifier_type)
        ) {
            message.error("Customer harus dipilih.");
            return;
        }

        if (this.state.user_identifier_type === "search") {
            if (empty(this.state.user_identifier_string)) {
                message.error("Kata kunci pencarian harus diisi.");
                return;
            }
        }

        this.setSearchUserLoading(true);
        apiCall(
            endpoint.GET_MEMBER_SEARCH + `?keyword=${this.state.user_identifier_string}`,
            "get",
            null,
            result => {
                this.setState({
                    userSearchResult: result.data.members
                });
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            () => {
                this.setSearchUserLoading(false);
            }
        );
    };

    selectSearchedCustomer = user => {
        let user_identifier_type = "";
        let user_identifier_string = "";

        if (!empty(user.user_email)) {
            user_identifier_type = "email";
            user_identifier_string = user.user_email;
        }

        if (!empty(user.user_phone)) {
            user_identifier_type = "phone";
            user_identifier_string = user.user_phone;
        }

        this.setSearchUserLoading(true);
        apiCall(
            endpoint.GET_CUSTOMER_ACTIVITIES + `?user_identifier_type=${user_identifier_type}&user_identifier_string=${user_identifier_string}`,
            "get",
            null,
            result => {
                this.setState({
                    step: 2,
                    user: result.data,
                    user_identifier_type,
                    user_identifier_string
                }, () => {
                    this.getUserInfo();
                });
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            () => {
                this.setSearchUserLoading(false);
            }
        );
        this.getEmrData(user.user_code);
    };

    getEmrData = (userCode) => {
        apiCall(
            endpoint.GET_CUSTOMER_EMR + `?user_identifier_type=qr&user_identifier_string=${userCode}`,
            "get",
            null,
            result => {
                this.setState({
                    emrData: result.data
                  });
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            () => {
                this.setSearchUserLoading(false);
            }
        );
    }

    handleQrScan = data => {
        if (data) {
            this.setState({ user_identifier_string: data }, () => {
                this.onSearchCustomer();
            });
        }
    };

    handleQrError = err => {
        console.error(err);
    };

    onTakePhoto = (dataUri) => {
        // Do stuff with the photo...
        this.setState({
            activityPhoto: dataUri,
            takePhotoModalOpened: false
        });
    };

    onResetPhoto = () => {
        this.setState({
            activityPhoto: null,
            user: {
                ...this.state.user,
                user_photo: null
            }
        });
    };

    onCameraError = (error) => {
        message.error(`Camera error: ${error}`);
    };

    onAddPhoto = () => {
        let validForm = true;
        if (empty(this.state.activityPhoto)) {
            validForm = false;
            message.error("Foto harus diupload");
        }

        if (validForm) {
            const newActivityPhotos = this.state.activityPhotos.concat({
                id: uuid(),
                photo_url: this.state.activityPhoto,
                photo_desc: this.state.activityPhotoDesc
            });

            this.setState({
                activityPhotos: newActivityPhotos,
                activityPhoto: null,
                activityPhotoDesc: "",
                newActivityPhotoFormVisible: false
            });
        }
    };

    onDeletePhoto = (id) => {
        Modal.confirm({
            title: "Apakah Anda yakin ingin menghapus foto?",
            content: "",
            onOk: () => {
                const newActivityPhotos = this.state.activityPhotos.filter(row => row.id !== id);

                this.setState({
                    activityPhotos: newActivityPhotos
                });
            },
            onCancel: () => {
            }
        });
    };

    onSubmitCustomerActivity = e => {
        if (e !== 0) {
            e.preventDefault();
            e.stopPropagation();
        }

        const {
            user,
            local_trx_id,
            activityPhotos,
            activityNotes,
            activityNotesForCashier,
            pos_area_id,
            icd_10_id,
            soap_subject,
            soap_object,
            soap_assessment,
            soap_planning,
            isEditActivity
        } = this.state;

        this.setState({ customerActivityFormLoading: true });

        const apiUrl = isEditActivity ? endpoint.PUT_EDIT_CUSTOMER_ACTIVITIES : endpoint.POST_NEW_CUSTOMER_ACTIVITIES;
        const apiMethod = isEditActivity ? "put" : "post";
        const modalTitle = isEditActivity ? "Aktivitas pelanggan berhasil diubah" : "Aktivitas pelanggan berhasil ditambahkan";
        let formData = {
            local_trx_id: local_trx_id,
            user_code: user.user_code,
            activity_notes: activityNotes,
            notes_for_cashier: activityNotesForCashier,
            activity_photos: activityPhotos
        };

        if (this.props.configs.rme_satu_sehat === 1 && this.props.configs.is_satusehat_nakes === 1) {
            if(empty(pos_area_id)){
                message.error('Room harus dipilih!');
                this.setState({ customerActivityFormLoading: false });
                return false;
            }

            formData = {
                ...formData,
                pos_area_id,
                icd_10_id,
                soap_subject,
                soap_object,
                soap_assessment,
                soap_planning
            };
        }

        apiCall(
            apiUrl,
            apiMethod,
            formData,
            res => {
                Modal.success({
                    title: modalTitle,
                    content: "",
                    onOk: () => {
                        this.setState({
                            step: 2
                        }, () => {
                            this.setSearchUserLoading(true);
                            const { user_identifier_type, user_identifier_string } = this.state;
                            apiCall(
                                endpoint.GET_CUSTOMER_ACTIVITIES + `?user_identifier_type=${user_identifier_type}&user_identifier_string=${user_identifier_string}`,
                                "get",
                                null,
                                result => {
                                    this.setState({
                                        step: 2,
                                        user: result.data,
                                        user_identifier_type,
                                        user_identifier_string
                                    }, () => {
                                        this.getUserInfo();
                                    });
                                },
                                err => {
                                    ErrorHandler.handleGeneralError(err);
                                },
                                () => {
                                    this.setSearchUserLoading(false);
                                }
                            );
                        });
                    }
                });
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            () => {
                this.setState({
                    customerActivityFormLoading: false,
                    ...this.initialActivityFormState
                });
            }
        );
    };

    onCancelCustomer = () => {
        Modal.confirm({
            title: "Apakah Anda yakin ingin kembali?",
            content: "",
            onOk: () => {
                this.setState({ ...this.initialState, areas: this.state.areas });
            },
            onCancel: () => {
            }
        });
    };

    onNewActivityBtnClicked = () => {
        this.setState({
            step: 3,
            local_trx_id: uuid(),
            activityPhotos: [],
            activityPhoto: null,
            activityPhotoDesc: "",
            activityNotes: "",
            activityNotesForCashier: ""
        });
    };

    editActivity = (activity) => {
        const mappedPhotos = activity.customer_activity_photos.map(row => {
            return {
                id: uuid(),
                ...row
            };
        });

        this.setState({
            step: 3,
            local_trx_id: activity.local_trx_id,
            activityPhotos: mappedPhotos,
            activityPhoto: null,
            activityPhotoDesc: "",
            activityNotes: activity.activity_notes,
            activityNotesForCashier: activity.notes_for_cashier,
            pos_area_id: activity.pos_area_id,
            icd_10_id: activity?.icd_10_id,
            icd10Items: [{
                icd_10_id: activity?.icd_10_id,
                skri: activity?.skri,
                str: activity?.str,
            }],
            soap_subject: activity.soap_subject,
            soap_object: activity.soap_object,
            soap_assessment: activity.soap_assessment,
            soap_planning: activity.soap_planning,
            isEditActivity: true
        });
    };

    deleteActivity = activity => {
        if (window.confirm("Apakah Anda yakin ingin menghapus?")) {
            apiCall(
                endpoint.DELETE_DELETE_CUSTOMER_ACTIVITIES,
                "delete",
                {
                    local_trx_id: activity.local_trx_id
                },
                res => {
                    Modal.success({
                        title: "Aktivitas pelanggan berhasil dihapus",
                        content: "",
                        onOk: () => {
                            this.setState({
                                step: 2
                            }, () => {
                                this.setSearchUserLoading(true);
                                const { user_identifier_type, user_identifier_string } = this.state;
                                apiCall(
                                    endpoint.GET_CUSTOMER_ACTIVITIES + `?user_identifier_type=${user_identifier_type}&user_identifier_string=${user_identifier_string}`,
                                    "get",
                                    null,
                                    result => {
                                        this.setState({
                                            step: 2,
                                            user: result.data,
                                            user_identifier_type,
                                            user_identifier_string
                                        }, () => {
                                            this.getUserInfo();
                                        });
                                    },
                                    err => {
                                        ErrorHandler.handleGeneralError(err);
                                    },
                                    () => {
                                        this.setSearchUserLoading(false);
                                    }
                                );
                            });
                        }
                    });
                },
                err => {
                    ErrorHandler.handleGeneralError(err);
                },
                () => {
                    this.setState({
                        customerActivityFormLoading: false,
                        ...this.initialActivityFormState
                    });
                }
            );
        }
    };

    onCancelCustomerActivityForm = () => {
        Modal.confirm({
            title: "Apakah Anda yakin ingin membatalkan?",
            content: "",
            onOk: () => {
                this.setState({
                    step: 2
                });
            },
            onCancel: () => {
            }
        });
    };

    onChangeUploadPhoto = e => {
        const files = e.target.files;
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
            this.setState({
                activityPhoto: reader.result
            });
        };
        reader.onerror = err => {
            ErrorHandler.handleGeneralError(err);
        };
    };

    onUpdateJournalNotes = e => {
        const { retrievedMemberData, journalNotes } = this.state;
        const { configs } = this.props;

        this.setState({ journalNotesLoading: true });
        apiCall(
            endpoint.POST_EDIT_JOURNAL_NOTES,
            "post",
            {
                user_id: retrievedMemberData.user_id,
                journal_notes: journalNotes
            },
            response => {
                this.setState({
                    journalNotesInitial: journalNotes,
                    journalNotesModifiedBy: configs.business_user_firstname + " " + configs.business_user_lastname,
                    journalNotesModifiedAt: moment().format(MOMENT_SQL_DATETIME_FORMAT)
                });

                Modal.success({
                    title: "Sukses",
                    content: "Catatan jurnal telah berhasil disimpan."
                });
            },
            error => {
                ErrorHandler.handleGeneralError(error);
            },
            res => {
                this.setState({ journalNotesLoading: false });
            }
        );
    };

    getUserInfo = () => {
        const { user_identifier_type, user_identifier_string } = this.state;
        apiCall(
            endpoint.GET_CUSTOMER_INFO,
            "post",
            {
                user_identifier_type: user_identifier_type,
                user_identifier_string: user_identifier_string
            },
            res => {
                this.setState({
                    retrievedUserData: res.data,
                    journalNotes: res.data.journal_notes,
                    journalNotesInitial: res.data.journal_notes,
                    journalNotesModifiedAt: res.data.journal_notes_modified_at,
                    journalNotesModifiedBy: res.data.journal_notes_modified_by
                });
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            }
        );

        apiCall(
            endpoint.GET_MEMBER_INFO,
            "post",
            {
                user_identifier_type: user_identifier_type,
                user_identifier_string: user_identifier_string
            },
            res => {
                this.setState({
                    retrievedMemberData: res.data
                });
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            }
        );
    };

    onChangePhotoDesc = (descValue, id) => {
        const { activityPhotos } = this.state;

        const newActivityPhotos = activityPhotos.map(activityPhoto => {
            if (activityPhoto.id === id) {
                return {
                    ...activityPhoto,
                    photo_desc: descValue
                };
            } else {
                return activityPhoto;
            }
        });

        this.setState({
            activityPhotos: newActivityPhotos
        });
    };

    syncSatuSehatPatientId = () => {
        const form_data = {
            user_identifier_string: this.state.user_identifier_string,
            user_identifier_type: this.state.user_identifier_type
        };

        apiCall(
            endpoint.POST_SYNC_SATUSEHAT_PATIENT_ID,
            "post",
            form_data,
            result => {
                message.success("Sync ID Satu Sehat berhasil");
                this.onSearchCustomer();
            },
            err => {
                ErrorHandler.handleGeneralError(err);
            },
            () => {
            }
        );
    };

    handleIcd10Search = value => {
        if (value) {
            apiCall(
                endpoint.GET_SATUSEHAT_ICD_10 + '?search_term=' + value,
                "get",
                null,
                result => {
                    this.setState({icd10Items: result.data});
                },
                err => {
                    ErrorHandler.handleGeneralError(err);
                },
                () => {
                }
            );
        } else {
            this.setState({ icd10Items: [] });
        }
    };

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 }
            }
        };
        const resetCustomerBtnLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0
                },
                sm: {
                    span: 16,
                    offset: 8
                }
            }
        };
        const {
            step,
            user_identifier_type,
            user_identifier_string,
            user,
            journalFilter,
            activityPhotos,
            activityPhoto,
            activityPhotoDesc,
            activityNotes,
            activityNotesForCashier,
            pos_area_id,
            soap_subject,
            soap_object,
            soap_assessment,
            soap_planning,
            searchUserLoading,
            customerActivityFormLoading,
            newActivityPhotoFormVisible,
            chosenActivity,
            journalNotes,
            journalNotesInitial,
            journalNotesModifiedAt,
            journalNotesModifiedBy,
            areas,
            icd10Items,
            icd_10_id,
            emrData
        } = this.state;
        const { configs } = this.props;

        const activityColumns = [
            {
                title: "Tanggal Aktivitas",
                dataIndex: "created_at",
                key: "created_at",
                render: (data, row) => {
                    return moment(data, MOMENT_SQL_DATE_FORMAT).format(MOMENT_READABLE_DATE_FORMAT);
                }
            },
            {
                title: "Catatan Aktivitas",
                dataIndex: "activity_notes",
                key: "activity_notes",
                render: (data, row) => {
                    return nl2brReact(data);
                }
            },
            {
                title: "Catatan untuk Kasir",
                dataIndex: "notes_for_cashier",
                key: "notes_for_cashier",
                render: (data, row) => {
                    return nl2brReact(data);
                }
            },
            this.props.configs.rme_satu_sehat === 1 && {
                title: "Terkirim ke Satu Sehat",
                dataIndex: "synced_at",
                key: "synced_at",
                render: (data, row) => {
                    return data === null ? 
                    <Icon type="close-square" theme="twoTone" twoToneColor="red" /> : 
                    <span>
                        <Icon type="check-square" theme="twoTone" twoToneColor="#52c41a" />&nbsp;
                        {moment(data, MOMENT_SQL_DATE_FORMAT).format(MOMENT_READABLE_DATE_FORMAT)}
                    </span>
                }
            },
            {
                title: "Action",
                dataIndex: "customer_activity_id",
                key: "customer_activity_id",
                render: (data, row) => {
                    return (
                        <React.Fragment>
                            <Button type={"primary"}
                                    size={"small"}
                                    onClick={() => {
                                        this.setState({
                                            chosenActivity: row
                                        });
                                    }}
                                    style={{ marginRight: 5, marginBottom: 5 }}
                            >
                                Lihat Foto Aktivitas
                            </Button>
                            {row.business_user_id === configs.business_user_id && (
                                <React.Fragment>
                                    <Button type={"primary"}
                                            size={"small"}
                                            onClick={() => {
                                                this.editActivity(row);
                                            }}
                                            style={{ marginRight: 5, marginBottom: 5 }}
                                    >
                                        Ubah
                                    </Button>
                                    <Button type={"danger"}
                                            size={"small"}
                                            onClick={() => {
                                                this.deleteActivity(row);
                                            }}
                                            style={{ marginRight: 5, marginBottom: 5 }}
                                    >
                                        Hapus
                                    </Button>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    );
                }
            }
        ].filter(Boolean);

        const newActivityPhotoColumns = [
            {
                title: "Foto",
                dataIndex: "photo_url",
                key: "photo_url",
                render: (data, row) => {
                    if (data.includes("http")) {
                        return (<a href={data} target="_blank" rel="noopener noreferrer"><img src={data} style={{ maxWidth: 200 }} alt="Foto Aktivitas"/></a>);
                    } else {
                        return (<img src={data} style={{ maxWidth: 200 }} alt="Foto Aktivitas"/>);
                    }
                }
            },
            {
                title: "Deskripsi",
                dataIndex: "photo_desc",
                key: "photo_desc",
                render: (data, row) => {
                    return (<TextArea defaultValue={data} rows={5} onChange={(e) => {
                        this.onChangePhotoDesc(e.target.value, row.id);
                    }}/>);
                }
            },
            {
                title: "Action",
                dataIndex: "id",
                key: "id",
                render: (data, row) => {
                    return <Button onClick={() => {
                        this.onDeletePhoto(data);
                    }}
                                   type={"danger"}
                                   size={"small"}
                                   htmlType={"button"}>Hapus</Button>;
                }
            }
        ];

        const activityPhotoColumns = [
            {
                title: "Foto",
                dataIndex: "photo_url",
                key: "photo_url",
                render: (data, row) => {
                    if (data.includes("http")) {
                        return (<a href={data} target="_blank" rel="noopener noreferrer"><img src={data} style={{ maxWidth: 200 }} alt="Foto Aktivitas"/></a>);
                    } else {
                        return (<img src={data} style={{ maxWidth: 200 }} alt="Foto Aktivitas"/>);
                    }
                }
            },
            {
                title: "Deskripsi",
                dataIndex: "photo_desc",
                key: "photo_desc",
                render: (data, row) => {
                    return nl2brReact(data);
                }
            }
        ];

        const pageTitle = (
            <React.Fragment>
                <Icon type="snippets"/>
                &nbsp;&nbsp; Jurnal Pelanggan {!empty(user) && `(${user.user_name})`}
                {!empty(user) && (
                    <>
                        &nbsp;&nbsp;
                        <Link to={`/member-info/${this.state.user_identifier_type}/${this.state.user_identifier_string}`}>
                            <Button htmlType="button"
                                    type="primary"
                                    size="small"
                            >Lihat Informasi Pelanggan</Button>
                        </Link>
                        &nbsp;
                        <Button htmlType="button"
                                size="small"
                                type="danger"
                                onClick={this.onCancelCustomer}
                        >
                            Kembali
                        </Button>
                    </>
                )}
            </React.Fragment>
        );
        return (
            <Card title={pageTitle} bordered={false}>
                <Row gutter={48}>
                    <Col sm={24}>
                        {step === 1 && (
                            <Spin spinning={searchUserLoading}>
                                <Form onSubmit={this.onSearchCustomer}>
                                    <FormItem
                                        {...formItemLayout}
                                        label="Cari Customer Berdasarkan"
                                    >
                                        <Radio.Group
                                            defaultValue="phone"
                                            buttonStyle="solid"
                                            disabled={user !== null}
                                            value={user_identifier_type}
                                            onChange={
                                                this.onChangeUserIdentifierType
                                            }
                                        >
                                            <Radio.Button value="phone">
                                                Phone
                                            </Radio.Button>
                                            <Radio.Button value="qr">
                                                QR Code
                                            </Radio.Button>
                                            <Radio.Button value="email">
                                                Email
                                            </Radio.Button>
                                            <Radio.Button value="reference">
                                                Reference
                                            </Radio.Button>
                                            <Radio.Button value="search">
                                                Nama
                                            </Radio.Button>
                                            <Radio.Button value="nik">
                                                NIK
                                            </Radio.Button>
                                        </Radio.Group>
                                    </FormItem>

                                    {user_identifier_type === "phone" && (
                                        <FormItem
                                            {...formItemLayout}
                                            label="Phone Number"
                                        >
                                            <Input
                                                addonBefore="+62"
                                                onChange={
                                                    this
                                                        .onChangeUserIdentifierString
                                                }
                                                value={user_identifier_string}
                                                disabled={user !== null}
                                                size="large"
                                                type="tel"
                                            />
                                        </FormItem>
                                    )}

                                    {user_identifier_type === "qr" && (
                                        <FormItem
                                            {...formItemLayout}
                                            label="Scan QR Code"
                                        >
                                            <QrReader
                                                onScan={this.handleQrScan}
                                                onError={this.handleQrError}
                                                style={{
                                                    width: 300,
                                                    maxWidth: "100%",
                                                    marginBottom: 10
                                                }}
                                            />

                                            <Input
                                                placeholder="QR Code"
                                                onChange={
                                                    this
                                                        .onChangeUserIdentifierString
                                                }
                                                value={user_identifier_string}
                                                disabled={user !== null}
                                                size="large"
                                            />
                                        </FormItem>
                                    )}

                                    {user_identifier_type === "email" && (
                                        <FormItem
                                            {...formItemLayout}
                                            label="Email"
                                        >
                                            <Input
                                                onChange={
                                                    this
                                                        .onChangeUserIdentifierString
                                                }
                                                value={user_identifier_string}
                                                disabled={user !== null}
                                                size="large"
                                                type="email"
                                            />
                                        </FormItem>
                                    )}

                                    {user_identifier_type === "reference" && (
                                        <FormItem
                                            {...formItemLayout}
                                            label="Reference"
                                        >
                                            <Input
                                                onChange={
                                                    this
                                                        .onChangeUserIdentifierString
                                                }
                                                value={user_identifier_string}
                                                disabled={user !== null}
                                                size="large"
                                            />
                                        </FormItem>
                                    )}

                                    {user_identifier_type === "search" && (
                                        <FormItem
                                            {...formItemLayout}
                                            label="Nama Customer"
                                        >
                                            <Input.Search
                                                onSearch={
                                                    this
                                                        .onSearchCustomerByKeyword
                                                }
                                                onChange={
                                                    this
                                                        .onChangeUserIdentifierString
                                                }
                                                value={user_identifier_string}
                                                disabled={user !== null}
                                                enterButton="Cari"
                                                size="large"
                                            />

                                            {!empty(
                                                this.state.userSearchResult
                                            ) &&
                                                user === null && (
                                                    <List
                                                        style={{
                                                            marginTop: 10
                                                        }}
                                                        bordered
                                                        dataSource={
                                                            this.state
                                                                .userSearchResult
                                                        }
                                                        renderItem={item => (
                                                            <List.Item>
                                                                <div
                                                                    style={{
                                                                        cursor:
                                                                            "pointer",
                                                                        display:
                                                                            "block",
                                                                        width:
                                                                            "100%"
                                                                    }}
                                                                    onClick={() => {
                                                                        this.selectSearchedCustomer(
                                                                            item
                                                                        );
                                                                    }}
                                                                >
                                                                    <h4>
                                                                        {
                                                                            item.user_name
                                                                        }
                                                                        {!empty(
                                                                            item.user_id
                                                                        ) &&
                                                                            ` (${item.user_id})`}
                                                                    </h4>
                                                                    <p>
                                                                        {!empty(
                                                                            item.user_ref
                                                                        ) && (
                                                                            <React.Fragment>
                                                                                Ref:{" "}
                                                                                {
                                                                                    item.user_ref
                                                                                }
                                                                                <br />
                                                                            </React.Fragment>
                                                                        )}
                                                                        {!empty(
                                                                            item.user_phone
                                                                        ) && (
                                                                            <React.Fragment>
                                                                                {
                                                                                    item.user_phone
                                                                                }
                                                                                <br />
                                                                            </React.Fragment>
                                                                        )}
                                                                        {!empty(
                                                                            item.user_email
                                                                        ) && (
                                                                            <React.Fragment>
                                                                                {
                                                                                    item.user_email
                                                                                }
                                                                                <br />
                                                                            </React.Fragment>
                                                                        )}
                                                                        {!empty(
                                                                            item.user_notes
                                                                        ) && (
                                                                            <React.Fragment>
                                                                                {
                                                                                    item.user_notes
                                                                                }
                                                                                <br />
                                                                            </React.Fragment>
                                                                        )}
                                                                    </p>
                                                                </div>
                                                            </List.Item>
                                                        )}
                                                    />
                                                )}
                                        </FormItem>
                                    )}

                                    {user_identifier_type === "nik" && (
                                        <FormItem
                                            {...formItemLayout}
                                            label="NIK"
                                        >
                                            <Input
                                                onChange={
                                                    this
                                                        .onChangeUserIdentifierString
                                                }
                                                value={user_identifier_string}
                                                disabled={user !== null}
                                                size="large"
                                            />
                                        </FormItem>
                                    )}

                                    <FormItem {...resetCustomerBtnLayout}>
                                        <Button
                                            type="primary"
                                            size="large"
                                            block={true}
                                            htmlType="submit"
                                        >
                                            Submit
                                        </Button>
                                    </FormItem>
                                </Form>
                            </Spin>
                        )}
                        {step === 2 && user !== null && emrData !== null && (
                            // TODO: revert to default 1 on prod
                            <Tabs defaultActiveKey="1">
                                <Tabs.TabPane tab="Data Pelanggan" key="1">
                                    {this.props.configs.rme_satu_sehat === 1 &&
                                        this.props.configs
                                            .is_satusehat_nakes === 1 && (
                                            <>
                                                <div
                                                    style={{ marginBottom: 10 }}
                                                >
                                                    <h4>NIK</h4>
                                                    {empty(user.nik) && (
                                                        <div
                                                            style={{
                                                                color: RED
                                                            }}
                                                        >
                                                            Pasien belum
                                                            memiliki NIK
                                                        </div>
                                                    )}
                                                    {!empty(user.nik) && (
                                                        <div>{user.nik}</div>
                                                    )}
                                                </div>
                                                <div
                                                    style={{ marginBottom: 10 }}
                                                >
                                                    <h4>ID Satu Sehat</h4>
                                                    {empty(
                                                        user.satusehat_patient_id
                                                    ) && (
                                                        <div
                                                            style={{
                                                                color: RED
                                                            }}
                                                        >
                                                            Pasien belum
                                                            memiliki ID Satu
                                                            Sehat
                                                        </div>
                                                    )}
                                                    {!empty(user.nik) &&
                                                        empty(
                                                            user.satusehat_patient_id
                                                        ) && (
                                                            <div
                                                                style={{
                                                                    marginTop: 10
                                                                }}
                                                            >
                                                                <Button
                                                                    onClick={() => {
                                                                        this.syncSatuSehatPatientId();
                                                                    }}
                                                                    htmlType="button"
                                                                    type="primary"
                                                                    size="small"
                                                                >
                                                                    Sync
                                                                </Button>
                                                            </div>
                                                        )}
                                                    {!empty(
                                                        user.satusehat_patient_id
                                                    ) && (
                                                        <div>
                                                            {
                                                                user.satusehat_patient_id
                                                            }
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    <div style={{ marginBottom: 10 }}>
                                        <h4>Catatan Jurnal</h4>
                                        <Input.TextArea
                                            rows={7}
                                            onChange={e => {
                                                this.setState({
                                                    journalNotes: e.target.value
                                                });
                                            }}
                                            value={journalNotes}
                                        />
                                    </div>
                                    <div>
                                        <p>
                                            Last Modified by{" "}
                                            <strong>
                                                {journalNotesModifiedBy}
                                            </strong>{" "}
                                            At{" "}
                                            <strong>
                                                {journalNotesModifiedAt}
                                            </strong>
                                        </p>
                                        {journalNotesInitial !==
                                            journalNotes && (
                                            <p style={{ color: "crimson" }}>
                                                *Peringatan: Anda memiliki
                                                perubahan yang belum disimpan.
                                            </p>
                                        )}
                                    </div>
                                    <Button
                                        htmlType="button"
                                        size="default"
                                        type="primary"
                                        onClick={this.onUpdateJournalNotes}
                                    >
                                        Update Catatan
                                    </Button>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Aktivitas Pelanggan" key="2">
                                    <div style={{ marginBottom: 30 }}>
                                        <Button
                                            htmlType="button"
                                            size="default"
                                            type="primary"
                                            onClick={
                                                this.onNewActivityBtnClicked
                                            }
                                        >
                                            Buat Aktivitas Baru
                                        </Button>
                                    </div>

                                    <Input.Search
                                        type="text"
                                        onChange={e => {
                                            this.setState({
                                                journalFilter: e.target.value
                                            });
                                        }}
                                        value={journalFilter}
                                        placeholder="Cari.."
                                        style={{
                                            width: "500px",
                                            maxWidth: "100%",
                                            marginBottom: "20px"
                                        }}
                                    />

                                    <div
                                        style={{
                                            maxWidth: "100%",
                                            overflow: "auto"
                                        }}
                                    >
                                        <h4>Daftar Aktivitas Pelanggan</h4>
                                        <Table
                                            rowKey="created_at"
                                            columns={activityColumns}
                                            dataSource={user.activities.filter(
                                                row => {
                                                    const journalDate = moment(
                                                        row.created_at,
                                                        MOMENT_SQL_DATE_FORMAT
                                                    ).format(
                                                        MOMENT_READABLE_DATE_FORMAT
                                                    );
                                                    return (
                                                        String(
                                                            row.activity_notes
                                                        )
                                                            .toLowerCase()
                                                            .includes(
                                                                journalFilter.toLowerCase()
                                                            ) ||
                                                        String(
                                                            row.notes_for_cashier
                                                        )
                                                            .toLowerCase()
                                                            .includes(
                                                                journalFilter.toLowerCase()
                                                            ) ||
                                                        String(journalDate)
                                                            .toLowerCase()
                                                            .includes(
                                                                journalFilter.toLowerCase()
                                                            )
                                                    );
                                                }
                                            )}
                                            style={{ maxWidth: "100%" }}
                                        />
                                    </div>

                                    {!empty(chosenActivity) && (
                                        <Modal
                                            width={768}
                                            title={`Foto Aktivitas - ${moment(
                                                chosenActivity.created_at,
                                                MOMENT_SQL_DATE_FORMAT
                                            ).format(
                                                MOMENT_READABLE_DATE_FORMAT
                                            )}`}
                                            visible={true}
                                            footer={null}
                                            onOk={() => {
                                                this.setState({
                                                    chosenActivity: null
                                                });
                                            }}
                                            onCancel={() => {
                                                this.setState({
                                                    chosenActivity: null
                                                });
                                            }}
                                        >
                                            <div
                                                className={styles.formGroup}
                                                style={{
                                                    maxWidth: "100%",
                                                    overflow: "auto"
                                                }}
                                            >
                                                <h4>Foto Aktivitas</h4>
                                                <Table
                                                    rowKey="customer_activity_photo_id"
                                                    columns={
                                                        activityPhotoColumns
                                                    }
                                                    dataSource={
                                                        chosenActivity.customer_activity_photos
                                                    }
                                                    style={{ maxWidth: "100%" }}
                                                    locale={{
                                                        emptyText:
                                                            "Belum ada foto"
                                                    }}
                                                />
                                            </div>

                                            <div className={styles.formGroup}>
                                                <h4>Catatan Aktivitas</h4>
                                                <p>
                                                    {empty(
                                                        chosenActivity.activity_notes
                                                    )
                                                        ? "-"
                                                        : nl2brReact(
                                                              chosenActivity.activity_notes
                                                          )}
                                                </p>
                                            </div>

                                            <div className={styles.formGroup}>
                                                <h4>Catatan untuk Kasir</h4>
                                                <p>
                                                    {empty(
                                                        chosenActivity.notes_for_cashier
                                                    )
                                                        ? "-"
                                                        : nl2brReact(
                                                              chosenActivity.notes_for_cashier
                                                          )}
                                                </p>
                                            </div>

                                            {this.props.configs
                                                .rme_satu_sehat === 1 &&
                                                this.props.configs
                                                    .is_satusehat_nakes ===
                                                    1 && (
                                                    <>
                                                        <br />
                                                        <br />
                                                        <h2
                                                            style={{
                                                                marginBottom: 5
                                                            }}
                                                        >
                                                            Rekam Medis
                                                            Elektronik
                                                        </h2>
                                                        <h4
                                                            style={{
                                                                marginBottom: 15,
                                                                color:
                                                                    "slategrey"
                                                            }}
                                                        >
                                                            Terkoneksi ke
                                                            SATUSEHAT
                                                        </h4>
                                                        <div
                                                            className={
                                                                styles.formGroup
                                                            }
                                                        >
                                                            <h4>Room</h4>
                                                            <p>
                                                                {empty(
                                                                    chosenActivity.pos_area_id
                                                                )
                                                                    ? "-"
                                                                    : areas.find(
                                                                          area =>
                                                                              area.pos_area_id ===
                                                                              chosenActivity.pos_area_id
                                                                      )
                                                                          ?.area_name}
                                                            </p>
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.formGroup
                                                            }
                                                        >
                                                            <h4>ICD 10</h4>
                                                            <p>
                                                                {empty(
                                                                    chosenActivity.icd_10_id
                                                                )
                                                                    ? "-"
                                                                    : `[${chosenActivity.skri}] ${chosenActivity.str}`}
                                                            </p>
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.formGroup
                                                            }
                                                        >
                                                            <h4>Subject</h4>
                                                            <p>
                                                                {empty(
                                                                    chosenActivity.soap_subject
                                                                )
                                                                    ? "-"
                                                                    : nl2brReact(
                                                                          chosenActivity.soap_subject
                                                                      )}
                                                            </p>
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.formGroup
                                                            }
                                                        >
                                                            <h4>Object</h4>
                                                            <p>
                                                                {empty(
                                                                    chosenActivity.soap_object
                                                                )
                                                                    ? "-"
                                                                    : nl2brReact(
                                                                          chosenActivity.soap_object
                                                                      )}
                                                            </p>
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.formGroup
                                                            }
                                                        >
                                                            <h4>Assessment</h4>
                                                            <p>
                                                                {empty(
                                                                    chosenActivity.soap_assessment
                                                                )
                                                                    ? "-"
                                                                    : nl2brReact(
                                                                          chosenActivity.soap_assessment
                                                                      )}
                                                            </p>
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.formGroup
                                                            }
                                                        >
                                                            <h4>Planning</h4>
                                                            <p>
                                                                {empty(
                                                                    chosenActivity.soap_planning
                                                                )
                                                                    ? "-"
                                                                    : nl2brReact(
                                                                          chosenActivity.soap_planning
                                                                      )}
                                                            </p>
                                                        </div>
                                                    </>
                                                )}

                                            <div
                                                className={styles.formGroup}
                                                style={{ marginTop: 100 }}
                                            >
                                                <p>
                                                    <i>
                                                        Last update by{" "}
                                                        {chosenActivity.modified_by
                                                            .split(" ")
                                                            .slice(2)
                                                            .join(" ")}{" "}
                                                        at{" "}
                                                        {
                                                            chosenActivity.modified_at
                                                        }
                                                    </i>
                                                </p>
                                            </div>
                                        </Modal>
                                    )}
                                </Tabs.TabPane>
                                {this.props.configs.rme_satu_sehat === 1 &&
                                    this.props.configs.is_satusehat_nakes ===
                                        1 && (
                                        <Tabs.TabPane
                                            tab="Screening Rawat Jalan"
                                            key="3"
                                        >
                                            <ScreeningRawatJalan
                                                datas={JSON.parse(
                                                    this.state.emrData
                                                        ?.screening_rawat_jalan
                                                )}
                                                getEmrData={this.getEmrData}
                                                userCode={user.user_code}
                                            />
                                        </Tabs.TabPane>
                                    )}
                                {this.props.configs.rme_satu_sehat === 1 &&
                                    this.props.configs.is_satusehat_nakes ===
                                        1 && (
                                        <Tabs.TabPane
                                            tab="Riwayat Alergi"
                                            key="4"
                                        >
                                            <RiwayatAlergi
                                                datas={JSON.parse(
                                                    this.state.emrData
                                                        ?.riwayat_alergi
                                                )}
                                                getEmrData={this.getEmrData}
                                                userCode={user.user_code}
                                            />
                                        </Tabs.TabPane>
                                    )}
                                {this.props.configs.rme_satu_sehat === 1 &&
                                    this.props.configs.is_satusehat_nakes ===
                                        1 && (
                                        <Tabs.TabPane
                                            tab="Rekam Medis Tubuh"
                                            key="5"
                                        >
                                            <RekamMedisTubuh
                                                datas={JSON.parse(
                                                    this.state.emrData
                                                        ?.rekam_medis_tubuh
                                                )}
                                                getEmrData={this.getEmrData}
                                                userCode={user.user_code}
                                            />
                                        </Tabs.TabPane>
                                    )}
                                {this.props.configs.rme_satu_sehat === 1 &&
                                    this.props.configs.is_satusehat_nakes ===
                                        1 && (
                                        <Tabs.TabPane
                                            tab="Persetujuan Medis"
                                            key="6"
                                        >
                                            <PersetujuanMedis
                                                datas={JSON.parse(
                                                    this.state.emrData
                                                        ?.persetujuan_medis
                                                )}
                                                getEmrData={this.getEmrData}
                                                userCode={user.user_code}
                                            />
                                        </Tabs.TabPane>
                                    )}
                                {this.props.configs.rme_satu_sehat === 1 &&
                                    this.props.configs.is_satusehat_nakes ===
                                        1 && (<Tabs.TabPane
                                        tab="Resume Perencanaan Pulang (Discharge Planning Form)"
                                        key="7"
                                        >
                                            <PerencanaanPulang
                                                datas={JSON.parse(
                                                    this.state.emrData
                                                        ?.discharge_planning
                                                )}
                                                getEmrData={this.getEmrData}
                                                userCode={user.user_code}
                                            />
                                        </Tabs.TabPane>
                                    )}
                            </Tabs>
                        )}

                        {step === 3 && user !== null && (
                            <React.Fragment>
                                <Spin spinning={customerActivityFormLoading}>
                                    <Form
                                        onSubmit={this.onSubmitCustomerActivity}
                                    >
                                        <div
                                            className={styles.formGroup}
                                            style={{
                                                maxWidth: "100%",
                                                overflow: "auto"
                                            }}
                                        >
                                            <label>Foto Aktivitas</label>
                                            <Table
                                                rowKey="id"
                                                columns={
                                                    newActivityPhotoColumns
                                                }
                                                dataSource={activityPhotos}
                                                style={{ maxWidth: "100%" }}
                                                locale={{
                                                    emptyText: "Belum ada foto"
                                                }}
                                            />

                                            {!newActivityPhotoFormVisible && (
                                                <div
                                                    style={{
                                                        margin: "10px 0",
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <Button
                                                        type="primary"
                                                        htmlType="button"
                                                        icon="camera"
                                                        onClick={() => {
                                                            this.setState({
                                                                newActivityPhotoFormVisible: true
                                                            });
                                                        }}
                                                    >
                                                        Tambahkan Foto Baru
                                                    </Button>
                                                </div>
                                            )}

                                            {newActivityPhotoFormVisible && (
                                                <Card style={{ marginTop: 10 }}>
                                                    <h4>Tambah Foto Baru</h4>
                                                    <div>
                                                        {!empty(
                                                            activityPhoto
                                                        ) && (
                                                            <div>
                                                                <img
                                                                    style={{
                                                                        maxWidth: 300,
                                                                        width:
                                                                            "100%"
                                                                    }}
                                                                    src={
                                                                        empty(
                                                                            activityPhoto
                                                                        )
                                                                            ? user.user_photo
                                                                            : activityPhoto
                                                                    }
                                                                    alt="Customer"
                                                                />
                                                            </div>
                                                        )}

                                                        {empty(
                                                            activityPhoto
                                                        ) && (
                                                            <React.Fragment>
                                                                <Button
                                                                    type="primary"
                                                                    htmlType="button"
                                                                    icon="camera"
                                                                    style={{
                                                                        marginTop: 8
                                                                    }}
                                                                    onClick={() => {
                                                                        this.setState(
                                                                            {
                                                                                takePhotoModalOpened: true
                                                                            }
                                                                        );
                                                                    }}
                                                                >
                                                                    Ambil foto
                                                                    dari kamera
                                                                </Button>
                                                                &nbsp;&nbsp;
                                                                atau
                                                                &nbsp;&nbsp;
                                                                <input
                                                                    type="file"
                                                                    id="newPhotoFileInput"
                                                                    style={{
                                                                        display:
                                                                            "none"
                                                                    }}
                                                                    onChange={
                                                                        this
                                                                            .onChangeUploadPhoto
                                                                    }
                                                                />
                                                                <label
                                                                    htmlFor="newPhotoFileInput"
                                                                    style={{
                                                                        fontWeight:
                                                                            "bold",
                                                                        textDecoration:
                                                                            "underline",
                                                                        cursor:
                                                                            "pointer"
                                                                    }}
                                                                >
                                                                    Upload File
                                                                </label>
                                                            </React.Fragment>
                                                        )}

                                                        {!empty(
                                                            activityPhoto
                                                        ) && (
                                                            <Button
                                                                type="danger"
                                                                htmlType="button"
                                                                icon="delete"
                                                                style={{
                                                                    marginTop: 8
                                                                }}
                                                                onClick={
                                                                    this
                                                                        .onResetPhoto
                                                                }
                                                            >
                                                                Ganti foto
                                                            </Button>
                                                        )}

                                                        <Modal
                                                            title="Ambil Foto"
                                                            visible={
                                                                this.state
                                                                    .takePhotoModalOpened
                                                            }
                                                            footer={null}
                                                            onOk={() => {
                                                                this.setState({
                                                                    takePhotoModalOpened: false
                                                                });
                                                            }}
                                                            onCancel={() => {
                                                                this.setState({
                                                                    takePhotoModalOpened: false
                                                                });
                                                            }}
                                                        >
                                                            <Camera
                                                                onTakePhoto={dataUri => {
                                                                    this.onTakePhoto(
                                                                        dataUri
                                                                    );
                                                                }}
                                                                onCameraError={error => {
                                                                    this.onCameraError(
                                                                        error
                                                                    );
                                                                }}
                                                                idealFacingMode={
                                                                    FACING_MODES.ENVIRONMENT
                                                                }
                                                                idealResolution={{
                                                                    width: 400,
                                                                    height: 400
                                                                }}
                                                                imageType={
                                                                    IMAGE_TYPES.JPG
                                                                }
                                                                imageCompression={
                                                                    0.97
                                                                }
                                                                isMaxResolution={
                                                                    false
                                                                }
                                                                isImageMirror={
                                                                    false
                                                                }
                                                                isDisplayStartCameraError={
                                                                    true
                                                                }
                                                                sizeFactor={1}
                                                            />
                                                        </Modal>
                                                    </div>
                                                    <br />
                                                    <h4>Deskripsi Foto</h4>
                                                    <Input.TextArea
                                                        onChange={e => {
                                                            this.setState({
                                                                activityPhotoDesc:
                                                                    e.target
                                                                        .value
                                                            });
                                                        }}
                                                        rows={3}
                                                        value={
                                                            activityPhotoDesc
                                                        }
                                                    />
                                                    <br />
                                                    <br />
                                                    <Button
                                                        type="primary"
                                                        htmlType="button"
                                                        onClick={
                                                            this.onAddPhoto
                                                        }
                                                    >
                                                        Tambahkan
                                                    </Button>
                                                </Card>
                                            )}
                                        </div>

                                        <br />
                                        <br />

                                        <div className={styles.formGroup}>
                                            <label>Catatan Aktivitas</label>
                                            <Input.TextArea
                                                onChange={e => {
                                                    this.setState({
                                                        activityNotes:
                                                            e.target.value
                                                    });
                                                }}
                                                rows={7}
                                                value={activityNotes}
                                            />
                                        </div>

                                        <div className={styles.formGroup}>
                                            <label>Catatan untuk Kasir</label>
                                            <Input.TextArea
                                                onChange={e => {
                                                    this.setState({
                                                        activityNotesForCashier:
                                                            e.target.value
                                                    });
                                                }}
                                                rows={7}
                                                value={activityNotesForCashier}
                                            />
                                        </div>

                                        {this.props.configs.rme_satu_sehat ===
                                            1 &&
                                            this.props.configs
                                                .is_satusehat_nakes === 1 && (
                                                <>
                                                    <br />
                                                    <br />
                                                    <h2
                                                        style={{
                                                            marginBottom: 5
                                                        }}
                                                    >
                                                        Rekam Medis Elektronik
                                                    </h2>
                                                    <h4
                                                        style={{
                                                            marginBottom: 15,
                                                            color: "slategrey"
                                                        }}
                                                    >
                                                        terkoneksi ke SATUSEHAT
                                                    </h4>

                                                    <div
                                                        className={
                                                            styles.formGroup
                                                        }
                                                    >
                                                        <label>Room</label>
                                                        <Select
                                                            showSearch={true}
                                                            defaultValue={
                                                                pos_area_id
                                                            }
                                                            optionFilterProp={
                                                                "children"
                                                            }
                                                            filterOption={(
                                                                input,
                                                                option
                                                            ) =>
                                                                option.props.children
                                                                    .toLowerCase()
                                                                    .indexOf(
                                                                        input.toLowerCase()
                                                                    ) >= 0
                                                            }
                                                            onChange={val => {
                                                                this.setState({
                                                                    pos_area_id: val
                                                                });
                                                            }}
                                                            placeholder={
                                                                "Select a room"
                                                            }
                                                        >
                                                            {areas.map(area => (
                                                                <Select.Option
                                                                    value={
                                                                        area.pos_area_id
                                                                    }
                                                                >
                                                                    {
                                                                        area.area_name
                                                                    }
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </div>

                                                    <div
                                                        className={
                                                            styles.formGroup
                                                        }
                                                    >
                                                        <label>ICD 10</label>
                                                        <Select
                                                            showSearch={true}
                                                            defaultValue={
                                                                icd_10_id
                                                            }
                                                            filterOption={false}
                                                            onChange={val => {
                                                                this.setState({
                                                                    icd_10_id: val
                                                                });
                                                            }}
                                                            onSearch={debounce(
                                                                this
                                                                    .handleIcd10Search,
                                                                300
                                                            )}
                                                            placeholder={
                                                                "Select a diagnosis"
                                                            }
                                                        >
                                                            {icd10Items.map(
                                                                item => (
                                                                    <Select.Option
                                                                        key={
                                                                            item.skri
                                                                        }
                                                                        value={
                                                                            item.icd_10_id
                                                                        }
                                                                    >
                                                                        [
                                                                        {
                                                                            item.skri
                                                                        }
                                                                        ]{" "}
                                                                        {
                                                                            item.str
                                                                        }
                                                                    </Select.Option>
                                                                )
                                                            )}
                                                        </Select>
                                                    </div>

                                                    <div
                                                        className={
                                                            styles.formGroup
                                                        }
                                                    >
                                                        <label>Subject</label>
                                                        <Input.TextArea
                                                            onChange={e => {
                                                                this.setState({
                                                                    soap_subject:
                                                                        e.target
                                                                            .value
                                                                });
                                                            }}
                                                            rows={7}
                                                            value={soap_subject}
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.formGroup
                                                        }
                                                    >
                                                        <label>Object</label>
                                                        <Input.TextArea
                                                            onChange={e => {
                                                                this.setState({
                                                                    soap_object:
                                                                        e.target
                                                                            .value
                                                                });
                                                            }}
                                                            rows={7}
                                                            value={soap_object}
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.formGroup
                                                        }
                                                    >
                                                        <label>
                                                            Assessment
                                                        </label>
                                                        <Input.TextArea
                                                            onChange={e => {
                                                                this.setState({
                                                                    soap_assessment:
                                                                        e.target
                                                                            .value
                                                                });
                                                            }}
                                                            rows={7}
                                                            value={
                                                                soap_assessment
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.formGroup
                                                        }
                                                    >
                                                        <label>Planning</label>
                                                        <Input.TextArea
                                                            onChange={e => {
                                                                this.setState({
                                                                    soap_planning:
                                                                        e.target
                                                                            .value
                                                                });
                                                            }}
                                                            rows={7}
                                                            value={
                                                                soap_planning
                                                            }
                                                        />
                                                    </div>
                                                </>
                                            )}

                                        <div className={styles.formGroup}>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                            >
                                                Submit
                                            </Button>
                                            &nbsp;
                                            <Button
                                                type="danger"
                                                htmlType="button"
                                                onClick={
                                                    this
                                                        .onCancelCustomerActivityForm
                                                }
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </Form>
                                </Spin>
                            </React.Fragment>
                        )}
                    </Col>
                </Row>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        configs: state.authState.userData
    };
};

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerJournal);


