import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Empty,
    Form,
    Input,
    Modal,
    Row,
    Select,
    Spin,
    Table
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import React, { useRef, useState } from "react";
import uuid from "uuid/v4";
import ErrorHandler from "../../shared/classes/ErrorHandler";
import { apiCall } from "../../shared/helpers/networkHelper";
import { GET_CUSTOMER_EMR } from "../../shared/utils/endpoints";
import { SignaturePadModal } from "./PersetujuanMedis";

export default function PerencanaanPulang({ datas, getEmrData, userCode }) {
    const initialFormState = {
        namaPasien: "",
        gender: "",
        umur: 0,
        tanggalMasuk: "",
        tanggalKeluar: "",
        dirujukKe: "",
        status: "",

        mobilisasi: "",
        alatBantu: "",
        masalahKeperawatan: "",

        tanggalKontrol: "",
        poli: "",
        dokter: "",
        penyuluhan: [],

        dokumentasi: [],
        obat: [],
        penanggungJawab: "",
        petugasRuangan: ""
    };
    const [formMode, setFormMode] = useState("add");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState(initialFormState);
    const [isLoading, setIsLoading] = useState(false);

    // nested modal (add obat) states
    const [isAddObat, setIsAddObat] = useState(false);
    const [obatData, setObatData] = useState({});

    // signature pad states & refs
    const [isPenanggungSign, setIsPenanggungSign] = useState(false);
    const [isPetugasSign, setIsPetugasSign] = useState(false);

    const penanggungJawabRef = useRef();
    const petugasRuanganRef = useRef();

    // signature pad handler
    const clearSignature = type => {
        if (type === "penanggungJawab") {
            penanggungJawabRef.current.clear();
        } else {
            petugasRuanganRef.current.clear();
        }
    };

    const handleSaveSignature = type => {
        const signatureDataUrl =
            type === "penanggungJawab"
                ? penanggungJawabRef.current.getTrimmedCanvas().toDataURL()
                : petugasRuanganRef.current.getTrimmedCanvas().toDataURL();
        setFormData(prevValues => ({
            ...prevValues,
            [type === "penanggungJawab"
                ? "penanggungJawab"
                : "petugasRuangan"]: signatureDataUrl
        }));
    };
    
    // nested modal handler (add obat)
    const handleObatInputChange = key => e => {
        const value = e.target ? e.target.value : e;
        setObatData(prevData => ({
            ...prevData,
            [key]: value
        }));
    };
    
    // main modal handler
    const handleInputChange = key => e => {
        const value = e.target ? e.target.value : e;
        setFormData(prevData => ({
            ...prevData,
            [key]: value
        }));
    };

    const handleOk = () => {
        setIsLoading(true);

        const currentDatas = Array.isArray(datas) ? datas : [];

        if (formMode === "add") {
            const newEntry = {
                ...formData,
                uuid: uuid(),
                createdAt: new Date().toISOString()
            };
    
            const newDatas = [...currentDatas, newEntry];
    
            apiCall(
                GET_CUSTOMER_EMR,
                "put",
                {
                    user_code: userCode,
                    discharge_planning: newDatas
                },
                result => {
                    getEmrData(userCode);
                    setIsModalOpen(false);
                    setFormData(initialFormState);
                    setIsLoading(false);
                },
                err => {
                    console.error(err)
                    ErrorHandler.handleGeneralError(err);
                    setIsLoading(false);
                }
            );
        } else {
            const updatedDatas = currentDatas.map(entry =>
                entry.uuid === formData.uuid
                    ? {
                            ...entry,
                            ...formData,
                            updatedAt: new Date().toISOString()
                        }
                    : entry
            );

            apiCall(
                GET_CUSTOMER_EMR,
                "put",
                {
                    user_code: userCode,
                    discharge_planning: updatedDatas
                },
                result => {
                    getEmrData(userCode);
                    setIsModalOpen(false);
                    setFormData(initialFormState);
                    setIsLoading(false);
                },
                err => {
                    ErrorHandler.handleGeneralError(err);
                    setIsLoading(false);
                }
            );
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setFormMode("add");
        setFormData(initialFormState);
    };

    const handleEdit = record => {
        setFormData(record);
        setFormMode("edit");
        setIsModalOpen(true);
    };

    const handleView = record => {
        setFormData(record);
        setFormMode("view");
        setIsModalOpen(true);
    };

    function showDeleteConfirm(record) {
        Modal.confirm({
            title: "Yakin ingin menghapus?",
            content: "Data akan dihapus",
            okText: "Hapus",
            okType: "danger",
            cancelText: "Batal",
            onOk() {
                setIsLoading(true);
                const updatedDatas = datas.filter(
                    entry => entry.uuid !== record.uuid
                );

                apiCall(
                    GET_CUSTOMER_EMR,
                    "put",
                    {
                        user_code: userCode,
                        discharge_planning:
                            updatedDatas.length > 0 ? updatedDatas : "{}"
                    },
                    result => {
                        getEmrData(userCode);
                        setIsLoading(false);
                    },
                    err => {
                        ErrorHandler.handleGeneralError(err);
                        setIsLoading(false);
                    }
                );
            },
            onCancel() {
                console.log("Cancel");
            }
        });
    }

    const penyuluhanOptions = [
        "Pemberian makan/minum",
        "Pemberian obat",
        "Perawatan luka",
        "Melakukan teknis relaksasi",
        "Batuk efektif",
        "Menggunakan alat bantu",
        "Hearing aid",
        "Kateter",
        "Pantangan/larangan",
        "Perawatan payudara",
        "Membuat larutan PK/betadin",
        "Pengaturan diet",
        "NGT",
        "Infus",
        "Oksigen",
        "Lain - Lain"
    ];

    const dokumentasiOptions = [
        "RADIOLOGI",
        "CT SCAN",
        "RO PHOTO",
        "LAIN - LAIN",
        "DIAGNOSA",
        "USG",
        "EKG",
        "ECHO",
        "Hasil Pemeriksaan Darah",
        "Surat Keterangan lahir",
        "Surat tanda keluar",
        "Surat Istirahat Sakit",
        "Surat Kontrol",
        "Kartu Golongan Darah",
        "Surat keterangan untuk asuransi",
        "Buku bayi / kartu imunisasi"
    ];

    const obatColumns = [
        { title: "Nama Obat", dataIndex: "namaObat", key: "namaObat" },
        { title: "Dosis", dataIndex: "dosis", key: "dosis" },
        {
            title: "Jam Pemberian",
            dataIndex: "jamPemberian",
            key: "jamPemberian"
        },
        { title: "Jumlah", dataIndex: "jumlah", key: "jumlah" },
        { title: "Keterangan", dataIndex: "keterangan", key: "keterangan" }
    ];

    return (
        <>
            <div style={{ marginBottom: "2rem" }}>
                <Button
                    htmlType="button"
                    size="default"
                    type="primary"
                    onClick={() => {
                        setFormMode("add");
                        setIsModalOpen(true);
                    }}
                >
                    Buat Perencanaan Pulang Baru
                </Button>
            </div>

            <h4>Perencanaan Pulang Pelanggan</h4>
            <Spin spinning={isLoading}>
                {Array.isArray(datas) && datas.length > 0 ? (
                    <div style={{ overflowX: "auto" }}>
                        <Table
                            dataSource={datas}
                            rowKey={record => record.uuid}
                            key={datas.length}
                        >
                            <Table.Column
                                title="Tanggal Masuk"
                                dataIndex="tanggalMasuk"
                                key="tanggalMasuk"
                                render={(_, record) =>
                                    moment(record.createdAt).format(
                                        "DD-MM-YYYY hh:mm"
                                    )
                                }
                            />
                            <Table.Column
                                title="Tanggal Keluar"
                                dataIndex="tanggalKeluar"
                                key="tanggalKeluar"
                                render={(_, record) =>
                                    moment(record.createdAt).format(
                                        "DD-MM-YYYY hh:mm"
                                    )
                                }
                            />
                            <Table.Column
                                title="Masalah Keperawatan"
                                dataIndex="masalahKeperawatan"
                                key="masalahKeperawatan"
                            />
                            <Table.Column
                                title="Action"
                                key="action"
                                render={(text, record) => (
                                    <>
                                        <Button
                                            size="small"
                                            onClick={() => handleView(record)}
                                            style={{ marginRight: 8 }}
                                        >
                                            Lihat
                                        </Button>
                                        <Button
                                            size="small"
                                            onClick={() => handleEdit(record)}
                                            type="primary"
                                            style={{ marginRight: 8 }}
                                        >
                                            Ubah
                                        </Button>
                                        <Button
                                            size="small"
                                            onClick={() =>
                                                showDeleteConfirm(record)
                                            }
                                            type="danger"
                                            style={{ marginRight: 8 }}
                                        >
                                            Hapus
                                        </Button>
                                    </>
                                )}
                            />
                        </Table>
                    </div>
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
            </Spin>

            <Modal
                title={
                    formMode === "add"
                        ? "Add Perencanaan Pasien Pulang"
                        : formMode === "edit"
                        ? "Edit Perencanaan Pasien Pulang"
                        : "View Perencanaan Pasien Pulang"
                }
                visible={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                width="75%"
                okText={
                    formMode === "add"
                        ? "Add Perencanaan Pasien Pulang"
                        : formMode === "edit"
                        ? "Edit Perencanaan Pasien Pulang"
                        : "View Perencanaan Pasien Pulang"
                }
                okButtonProps={{ disabled: formMode === "view" }}
                maskClosable={false}
            >
                <Spin spinning={isLoading}>
                    <Form
                        layout="horizontal"
                        labelAlign="left"
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 20 }}
                    >
                        <Divider orientation="left">
                            <strong>Identitas Pasien</strong>
                        </Divider>

                        <Form.Item label="Nama Pasien">
                            {formMode === "view" ? (
                                <span>{formData.namaPasien}</span>
                            ) : (
                                <Input 
                                    value={formData.namaPasien} 
                                    onChange={handleInputChange("namaPasien")} 
                                    placeholder="Masukkan nama pasien" 
                                />
                            )}
                        </Form.Item>

                        <Form.Item label="Jenis Kelamin">
                            {formMode === "view" ? (
                                <span>{formData.gender}</span>
                            ) : (
                                <Select
                                    value={formData.gender === "" ? undefined : formData.gender} 
                                    onChange={handleInputChange("gender")} 
                                    placeholder="Pilih jenis kelamin"
                                >
                                    <Select.Option value="Laki-Laki">Laki-Laki</Select.Option>
                                    <Select.Option value="Perempuan">Perempuan</Select.Option>
                                </Select>
                            )}
                        </Form.Item>

                        <Form.Item label="Umur">
                            {formMode === "view" ? (
                                <span>{formData.umur} tahun</span>
                            ) : (
                                <Input
                                    value={formData.umur === 0 ? undefined : formData.umur}
                                    onChange={handleInputChange("umur")}
                                    type="number"
                                    min={0}
                                    placeholder="Masukkan umur"
                                />
                            )}
                        </Form.Item>

                        <Form.Item label="Tanggal Masuk">
                            {formMode === "view" ? (
                                <span>{moment(formData.tanggalMasuk).format('DD MMMM YYYY HH:mm')}</span>
                            ) : (
                                <DatePicker 
                                    allowClear={false}
                                    value={formData.tanggalMasuk === "" ? undefined : moment(formData.tanggalMasuk)} 
                                    onChange={date => handleInputChange("tanggalMasuk")(date.format())} 
                                    showTime 
                                    format="YYYY-MM-DD HH:mm" 
                                />
                            )}
                        </Form.Item>

                        <Form.Item label="Tanggal Keluar">
                            {formMode === "view" ? (
                                <span>{moment(formData.tanggalKeluar).format('DD MMMM YYYY HH:mm')}</span>
                            ) : (
                                <DatePicker
                                    allowClear={false}
                                    defaultValue={undefined}
                                    value={formData.tanggalKeluar === "" ? undefined : moment(formData.tanggalKeluar)} 
                                    onChange={date => handleInputChange("tanggalKeluar")(date.format())} 
                                    showTime 
                                    format="YYYY-MM-DD HH:mm" 
                                />
                            )}
                        </Form.Item>

                        <Divider orientation="left">
                            <strong>Catatan Pasien Pulang</strong>
                        </Divider>

                        <Form.Item label="Pasien dirujuk ke">
                            {formMode === "view" ? (
                                <span>
                                    {formData.dirujukKe === "dokter" ? "Dokter Pribadi" :
                                    formData.dirujukKe === "rs" ? "RS Lain" :
                                    formData.dirujukKe === "pulang" ? "Pulang" : ""}
                                </span>
                            ) : (
                                <Select 
                                    value={formData.dirujukKe === "" ? undefined : formData.dirujukKe} 
                                    onChange={handleInputChange("dirujukKe")} 
                                    placeholder="Pilih tujuan rujukan"
                                >
                                    <Select.Option value="dokter">Dokter Pribadi</Select.Option>
                                    <Select.Option value="rs">RS Lain</Select.Option>
                                    <Select.Option value="pulang">Pulang</Select.Option>
                                </Select>
                            )}
                        </Form.Item>

                        <Form.Item label="Status">
                            {formMode === "view" ? (
                                <span>
                                    {formData.status === "izin-dokter" ? "Atas Izin Dokter" :
                                    formData.status === "kabur" ? "Kabur" :
                                    formData.status === "pulang-paksa" ? "Pulang Paksa" :
                                    formData.status === "meninggal" ? "Meninggal" : ""}
                                </span>
                            ) : (
                                <Select 
                                    value={formData.status === "" ? undefined : formData.status} 
                                    onChange={handleInputChange("status")} 
                                    placeholder="Pilih status"
                                >
                                    <Select.Option value="izin-dokter">Atas Izin Dokter</Select.Option>
                                    <Select.Option value="kabur">Kabur</Select.Option>
                                    <Select.Option value="pulang-paksa">Pulang Paksa</Select.Option>
                                    <Select.Option value="meninggal">Meninggal</Select.Option>
                                </Select>
                            )}
                        </Form.Item>

                        <Divider orientation="left">
                            <strong>Kondisi pasien saat pulang/pindah</strong>
                        </Divider>

                        <Form.Item label="Mobilisasi">
                            {formMode === "view" ? (
                                <span>
                                    {formData.mobilisasi === "jalan" ? "Jalan" :
                                    formData.mobilisasi === "tongkat" ? "Tongkat" :
                                    formData.mobilisasi === "kursi-roda" ? "Kursi Roda" :
                                    formData.mobilisasi === "brankar" ? "Brankar" :
                                    formData.mobilisasi === "ngt" ? "NGT" : ""}
                                </span>
                            ) : (
                                <Select 
                                    value={formData.mobilisasi === "" ? undefined : formData.mobilisasi} 
                                    onChange={handleInputChange("mobilisasi")} 
                                    placeholder="Pilih mobilisasi"
                                >
                                    <Select.Option value="jalan">Jalan</Select.Option>
                                    <Select.Option value="tongkat">Tongkat</Select.Option>
                                    <Select.Option value="kursi-roda">Kursi Roda</Select.Option>
                                    <Select.Option value="brankar">Brankar</Select.Option>
                                    <Select.Option value="ngt">NGT</Select.Option>
                                </Select>
                            )}
                        </Form.Item>

                        <Form.Item label="Alat Bantu">
                {formMode === "view" ? (
                    <span>
                        {formData.alatBantu === "tidak-ada" ? "Tidak Ada" :
                         formData.alatBantu === "kateter" ? "Kateter" :
                         formData.alatBantu === "oksigen" ? "Oksigen Infus" :
                         formData.alatBantu === "lain" ? "Lain-Lain" : ""}
                    </span>
                ) : (
                    <Select 
                        value={formData.alatBantu === "" ? undefined : formData.alatBantu} 
                        onChange={handleInputChange("alatBantu")} 
                        placeholder="Pilih alat bantu"
                    >
                        <Select.Option value="tidak-ada">Tidak Ada</Select.Option>
                        <Select.Option value="kateter">Kateter</Select.Option>
                        <Select.Option value="oksigen">Oksigen Infus</Select.Option>
                        <Select.Option value="lain">Lain-Lain</Select.Option>
                    </Select>
                )}
                        </Form.Item>

                        <Form.Item label="Masalah Keperawatan">
                            {formMode === "view" ? (
                                <span>{formData.masalahKeperawatan}</span>
                            ) : (
                                <TextArea
                                    value={formData.masalahKeperawatan}
                                    onChange={handleInputChange("masalahKeperawatan")}
                                    rows={4}
                                    placeholder="Masukkan masalah keperawatan"
                                />
                            )}
                        </Form.Item>

                        <Divider orientation="left">
                            <strong>Kontrol Kembali Pada</strong>
                        </Divider>

                        <Form.Item label="Tanggal Kontrol">
                            {formMode === "view" ? (
                                <span>{moment(formData.tanggalKontrol).format('DD MMMM YYYY HH:mm')}</span>
                            ) : (
                                <DatePicker 
                                    allowClear={false}
                                    value={formData.tanggalKontrol === "" ? undefined : moment(formData.tanggalKontrol)} 
                                    onChange={date => handleInputChange("tanggalKontrol")(date.format())} 
                                    showTime 
                                    format="YYYY-MM-DD HH:mm" 
                                />
                            )}
                        </Form.Item>

                        <Form.Item label="Poli">
                            {formMode === "view" ? (
                                <span>{formData.poli}</span>
                            ) : (
                                <Input 
                                    value={formData.poli} 
                                    onChange={handleInputChange("poli")} 
                                    placeholder="Masukkan poli" 
                                />
                            )}
                        </Form.Item>

                        <Form.Item label="Dokter">
                            {formMode === "view" ? (
                                <span>{formData.dokter}</span>
                            ) : (
                                <Input 
                                    value={formData.dokter} 
                                    onChange={handleInputChange("dokter")} 
                                    placeholder="Masukkan nama dokter" 
                                />
                            )}
                        </Form.Item>

                        <Form.Item
                            labelCol={12}
                            wrapperCol={24}
                            label="Penyuluhan kesehatan yang telah diberikan perihal"
                        >
                            {formMode === "view" ? (
                                <span>{formData.penyuluhan.join(", ")}</span>
                            ) : (
                                <Checkbox.Group value={formData.penyuluhan} onChange={handleInputChange("penyuluhan")}>
                                    <Row>
                                        {penyuluhanOptions.map((option, index) => (
                                            <Col span={8} key={index}>
                                                <Checkbox value={option}>{option}</Checkbox>
                                            </Col>
                                        ))}
                                    </Row>
                                </Checkbox.Group>
                            )}
                        </Form.Item>

                        <Form.Item
                            labelCol={12}
                            wrapperCol={24}
                            label="Dokumentasi Pemeriksaan Yang Diserahkan"
                        >
                            {formMode === "view" ? (
                                <span>{formData.dokumentasi.join(", ")}</span>
                            ) : (
                                <Checkbox.Group 
                                    value={formData.dokumentasi} 
                                    onChange={handleInputChange("dokumentasi")}
                                >
                                    {dokumentasiOptions.map((option, index) => (
                                        <Col span={8} key={index}>
                                            <Checkbox value={option}>{option}</Checkbox>
                                        </Col>
                                    ))}
                                </Checkbox.Group>
                            )}
                        </Form.Item>

                        <Divider orientation="left">
                            <strong>Obat yang dilanjutkan di rumah</strong>
                        </Divider>

                        {/* Nested modal - to add obat yang dilanjut di rumah */}
                        <Button disabled={formMode === "view"} onClick={() => setIsAddObat(true)} style={{ marginBottom: "0.5rem"}} type="primary">Tambah Obat</Button>
                        <Modal
                            title="Tambahkan Obat yang Dilanjut di Rumah"
                            onOk={() => {
                                setFormData(prev => ({
                                    ...prev,
                                    obat: [...prev.obat, obatData]
                                }));
                                setIsAddObat(false);
                                setObatData({});
                            }}
                            onCancel={() => {
                                setIsAddObat(false);
                            }}
                            visible={isAddObat}
                            okText="Tambahkan Obat"
                        >
                            <Form
                                layout="horizontal"
                                labelAlign="left"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                            >
                                <Form.Item label="Nama Obat">
                                    <Input onChange={handleObatInputChange("namaObat")} placeholder="Nama Obat" disabled={formMode === "view"} />
                                </Form.Item>
                                <Form.Item label="Dosis">
                                    <Input onChange={handleObatInputChange("dosis")} placeholder="Dosis" disabled={formMode === "view"} />
                                </Form.Item>
                                <Form.Item label="Jam Pemberian">
                                    <Input onChange={handleObatInputChange("jamPemberian")} placeholder="Jam Pemberian" disabled={formMode === "view"} />
                                </Form.Item>
                                <Form.Item label="Jumlah">
                                    <Input onChange={handleObatInputChange("jumlah")} placeholder="Jumlah" disabled={formMode === "view"} />
                                </Form.Item>
                                <Form.Item label="Keterangan">
                                    <Input onChange={handleObatInputChange("keterangan")} placeholder="Keterangan" disabled={formMode === "view"} />
                                </Form.Item>
                            </Form>
                        </Modal>

                        <Table
                            dataSource={formData.obat}
                            columns={obatColumns}
                            pagination={false}
                        />

                        <Row style={{ marginTop: "2rem" }} type="flex" justify="space-around">
                            <Col style={{ display: "flex", flexDirection: "column", gap: "2em"}}>
                                <p>Yang Memberikan Persetujuan</p>
                                {formData.penanggungJawab !== "" && (
                                    <img
                                        src={formData.penanggungJawab}
                                        alt={"penanggung sign"}
                                        style={{
                                            width: "100%",
                                            maxWidth: "300px",
                                            height: "100px",
                                            marginBottom: "10px",
                                            objectFit: "contain",
                                        }}
                                    />
                                )}
                                <Button
                                    onClick={() =>
                                        setIsPenanggungSign(true)
                                    }
                                    disabled={formMode === "view"}
                                >
                                    Digital Signature
                                </Button>
                                <SignaturePadModal
                                    ref={penanggungJawabRef}
                                    visible={isPenanggungSign}
                                    onClose={() => {
                                        setIsPenanggungSign(false);
                                        clearSignature("penanggungJawab");
                                    }}
                                    onSave={() => {
                                        handleSaveSignature("penanggungJawab");
                                        setIsPenanggungSign(false);
                                    }}
                                    onClear={() => clearSignature("penanggungJawab")}
                                />
                            </Col>
                            <Col style={{ display: "flex", flexDirection: "column", gap: "2em"}}>
                                <p>Petugas Ruangan</p>
                                {formData.petugasRuangan !== "" && (
                                    <img
                                        src={formData.petugasRuangan}
                                        alt={"penanggung sign"}
                                        style={{
                                            width: "100%",
                                            maxWidth: "300px",
                                            height: "100px",
                                            marginBottom: "10px",
                                            objectFit: "contain",
                                        }}
                                    />
                                )}
                                <Button
                                    onClick={() =>
                                        setIsPetugasSign(true)
                                    }
                                    disabled={formMode === "view"}
                                >
                                    Digital Signature
                                </Button>
                                <SignaturePadModal
                                    ref={petugasRuanganRef}
                                    visible={isPetugasSign}
                                    onClose={() => {
                                        setIsPetugasSign(false);
                                        clearSignature("petugasRuangan");
                                    }}
                                    onSave={() => {
                                        handleSaveSignature("petugasRuangan");
                                        setIsPetugasSign(false);
                                    }}
                                    onClear={() => clearSignature("petugasRuangan")}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        </>
    );
}

