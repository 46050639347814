import { Button, Card, Col, Icon, Input, message, Row, Select, Spin } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Highlighter from "react-highlight-words";
import connect from "react-redux/es/connect/connect";
import { empty } from "../../shared/helpers/generalHelper";
import { DEFAULT_CURRENCY_SIGN } from "../../shared/utils/constants";
import { posOperations } from "../../state/ducks/pos";
import styles from "./OrganizationForm.module.scss";

class OrganizationForm extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.initialState = {
            formLoading: false,
            organization_id: this.props.match.params.organization_id,
            organizationData: {}
        };

        this.state = { ...this.initialState };
        this.currencyCode = this.props.posConfig.currency_code || DEFAULT_CURRENCY_SIGN;

        console.log(this.props.history.location.pathname);
    }

    componentDidMount() {
        if(this.state.organization_id !== "new"){
            this.loadOrganizationData();
        }
    }

    loadOrganizationData = () => {
        this.setState({ formLoading: true });

        this.setState(
            {
                id: 1,
                organization_name: 'Klinik XXX',
                parent_emr_organization: '',
                is_active: 1
            },
            () => {
                this.setState({ formLoading: false });
            }
        );
    };

    customFilterProps = (dataIndex, dataLabel, customRender = null) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataLabel}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => {
            let modifiedText = text || '';
            if(!empty(customRender)){
                modifiedText = customRender(text);
            }
            return (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={modifiedText}
                />
            );
        },
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    onSaveOrganization = () => {
        message.success("Organisasi telah berhasil disimpan");
        this.props.history.push("/emr/organization");
    };

    backToOrganizationList = () => {
        this.props.history.push("/emr/organization");
    }

    render() {
        const {
            formLoading
        } = this.state;

        const pageTitle = (
            <React.Fragment>
                Struktur Organisasi
            </React.Fragment>
        );

        return (
            <Card title={pageTitle} bordered={false}>
                <Row gutter={48}>
                    <Col xxl={24} sm={24}>
                        <Spin spinning={formLoading}>
                            <div className={styles.formContainer}>
                                <div className={styles.formItem}>
                                    <label>Nama Organisasi</label>
                                    <Input onChange={(e)=>{
                                        this.setState({
                                            organization_name: e.target.value
                                        });
                                    }} value={this.state.organization_name} />
                                </div>

                                <div className={styles.formItem}>
                                    <label>Bagian Dari</label>
                                    <Select
                                        showSearch={true}
                                        placeholder={"Pilih salah satu struktur organisasi"}
                                        onChange={(val)=>{
                                            this.setState({
                                                parent_organization_id: val
                                            });
                                        }}
                                        style={{width: '100%'}}
                                    >
                                        <Select.Option value={"1"}>Org 1</Select.Option>
                                        <Select.Option value={"2"}>Org 2</Select.Option>
                                    </Select>
                                </div>

                                <div className={styles.formItem}>
                                    <Button className={styles.actionButton}
                                            onClick={this.onSaveOrganization}
                                            type={"primary"}>
                                        Simpan
                                    </Button>
                                    <Button className={styles.actionButton}
                                            onClick={this.backToOrganizationList}
                                            type={"danger"}>
                                        Kembali
                                    </Button>
                                </div>
                            </div>
                        </Spin>
                    </Col>
                </Row>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        posConfig: state.authState.userData
    };
};

const mapDispatchToProps = {
    setNewLocalTrx: posOperations.setNewLocalTrx
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrganizationForm);