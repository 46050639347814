import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { message, Modal, Radio, InputNumber, Input } from "antd";
import { posOperations } from '../../state/ducks/pos';
import * as endpoint from '../../shared/utils/endpoints';
import { apiCall } from '../../shared/helpers/networkHelper';
import { empty } from '../../shared/helpers/generalHelper';
import { DEFAULT_CURRENCY_SIGN, DEFAULT_DECIMAL_SEPARATOR, DEFAULT_THOUSAND_SEPARATOR, MOMENT_SQL_DATE_FORMAT, MOMENT_SQL_DATETIME_FORMAT } from "../../shared/utils/constants";
import { processDecimalInput } from "../../shared/helpers/stringHelper";
import moment from "moment/moment";

class PosDiscountModal extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    static propTypes = {
        modalOpened: PropTypes.bool.isRequired,
        close: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.currencyCode = this.props.posConfig.currency_code || DEFAULT_CURRENCY_SIGN;
        this.decimalSeparator = this.props.appConfig.decimalSeparator || DEFAULT_DECIMAL_SEPARATOR;
        this.thousandSeparator = this.props.appConfig.thousandSeparator || DEFAULT_THOUSAND_SEPARATOR;
    }


    componentDidMount() {
        // if (navigator.onLine) {
        apiCall(
            endpoint.GET_POS_DISCOUNTS,
            'get',
            null,
            response => {
                this.props.storeDiscounts(response.data.pos_item_discounts);
            },
            response => {
                console.log(
                    'Fail to load discounts: ' + response.data.error.message
                );
            }
        );
        // }
    }

    onChangeDiscountType = e => {
        this.props.setTrxStates(this.props.currentLocalTrxId, {
            globalDiscountType: e.target.value
        });
    };

    onChangeDiscountPercent = e => {
        this.props.setTrxStates(this.props.currentLocalTrxId, {
            globalDiscountPercent: e
        });
    };

    onChangeDiscountNominal = e => {
        const localTrxId = this.props.currentLocalTrxId;
        const setTrxStates = this.props.setTrxStates;
        const inputCallback = (value, valueText) => {
            setTrxStates(localTrxId, {
                globalDiscountNominal: value,
                globalDiscountNominalText: valueText
            });
        };
        processDecimalInput(e.target.value, inputCallback, this.decimalSeparator, this.thousandSeparator);
    };

    onChangeDiscountItemId = e => {
        this.props.setTrxStates(this.props.currentLocalTrxId, {
            globalDiscountItemId: e.target.value
        });
    };

    handleModalOk = () => {
        let validForm = true;

        if (empty(this.props.globalDiscountType)) {
            validForm = false;
            message.error('Silakan pilih jenis diskon terlebih dahulu.');
        }

        if (
            this.props.globalDiscountType === 'promo' &&
            empty(this.props.globalDiscountItemId)
        ) {
            validForm = false;
            message.error('Silakan pilih promo terlebih dahulu.');
        }

        if (
            this.props.globalDiscountType === 'percentage' &&
            empty(this.props.globalDiscountPercent)
        ) {
            validForm = false;
            message.error(
                'Silakan masukkan persentase diskon terlebih dahulu.'
            );
        }

        if (
            this.props.globalDiscountType === 'value' &&
            empty(this.props.globalDiscountNominal)
        ) {
            validForm = false;
            message.error('Silakan masukkan nominal diskon terlebih dahulu.');
        }

        if (validForm) {
            let discountData = {};
            if (this.props.globalDiscountType === 'promo') {
                discountData = this.props.posDiscounts.find(
                    discount =>
                        discount.pos_item_discount_id ===
                        this.props.globalDiscountItemId
                );
                this.props.setTrxStates(this.props.currentLocalTrxId, {
                    globalDiscountPercent: 0,
                    globalDiscountNominal: 0
                });
            } else {
                if (this.props.globalDiscountType === 'percentage') {
                    this.props.setTrxStates(this.props.currentLocalTrxId, {
                        globalDiscountItemId: '',
                        globalDiscountNominal: 0
                    });
                } else {
                    this.props.setTrxStates(this.props.currentLocalTrxId, {
                        globalDiscountItemId: '',
                        globalDiscountPercent: 0
                    });
                }

                discountData = {
                    pos_item_discount_id: 0,
                    discount_type: 'custom',
                    deduction_type: this.props.globalDiscountType,
                    deduction_value:
                        this.props.globalDiscountType === 'percentage'
                            ? this.props.globalDiscountPercent
                            : this.props.globalDiscountNominal
                };
            }

            this.props.addTrxGlobalDisc(
                this.props.currentLocalTrxId,
                discountData
            );

            message.success('Discount successfully applied!');
            this.props.close();
        }
    };

    handleModalCancel = () => {
        this.props.close();
    };

    render() {
        const {
            posConfig,
            posDiscounts,
            globalDiscountType,
            globalDiscountPercent,
            globalDiscountNominalText,
            globalDiscountItemId,
            currentTrx
        } = this.props;
        const RadioGroup = Radio.Group;
        const RadioButton = Radio.Button;
        const currencyCode = posConfig.currency_code || DEFAULT_CURRENCY_SIGN;

        const discountAvailabilityFilter = (discounts) => {
            return discounts.filter(discount => {
                const currentDay = moment().format(MOMENT_SQL_DATE_FORMAT);
                const currentMoment = moment();
                const startTime = discount.start_date + " 00:00:01";
                const startTimeMoment = moment(startTime, MOMENT_SQL_DATETIME_FORMAT);
                const endTime = discount.end_date + " 23:59:59";
                const endTimeMoment = moment(endTime, MOMENT_SQL_DATETIME_FORMAT);
                let isActive = false;
                let isAvailable = false;

                if (currentMoment.isBetween(startTimeMoment, endTimeMoment, null, "[]")) {
                    isActive = true;
                }

                if(discount.availability_type === "all_day"){
                    isAvailable = true;
                }

                if (discount.availability_type === "special_day") {
                    discount.pos_item_discount_days.forEach(discountDay => {
                        const currentDayOfWeek = currentMoment.day();
                        const openHour = currentDay + " " + discountDay.open_hour;
                        const openHourMoment = moment(openHour, MOMENT_SQL_DATETIME_FORMAT);
                        const closeHour = currentDay + " " + discountDay.close_hour;
                        const closeHourMoment = moment(closeHour, MOMENT_SQL_DATETIME_FORMAT);

                        if (currentDayOfWeek === discountDay.day_id && currentMoment.isBetween(openHourMoment, closeHourMoment, null, "[]")) {
                            isAvailable = true;
                        }
                    });
                }

                if (discount.availability_type === "special_date") {
                    discount.pos_item_discount_special_dates.forEach(discountDate => {
                        const currentDateOfMonth = currentMoment.date();
                        const openHour = currentDay + " " + discountDate.start_time;
                        const openHourMoment = moment(openHour, MOMENT_SQL_DATETIME_FORMAT);
                        const closeHour = currentDay + " " + discountDate.end_time;
                        const closeHourMoment = moment(closeHour, MOMENT_SQL_DATETIME_FORMAT);

                        if (discountDate.day === currentDateOfMonth && currentMoment.isBetween(openHourMoment, closeHourMoment, null, "[]")) {
                            isAvailable = true;
                        }
                    });
                }

                let shouldBeFiltered = false;

                if(!empty(discount.authorized_user_groups)){
                    let authorized_user_groups_ids = discount.authorized_user_groups.split(',');

                    authorized_user_groups_ids = authorized_user_groups_ids.map(row => parseInt(row));

                    if(!authorized_user_groups_ids.includes(posConfig.business_user_group_id)){
                        shouldBeFiltered = true;
                    }
                }

                if(!empty(discount.authorized_business_memberships)){
                    if(empty(currentTrx.customer)){
                        shouldBeFiltered = true;
                    }else{
                        const customer_membership_name = currentTrx.customer.membership_name;
                        let hasRequiredMembership = false;

                        if(!empty(customer_membership_name)){
                            const authorized_business_memberships_array = discount.authorized_business_memberships.split(',');
                            authorized_business_memberships_array.forEach(authorized_business_membership_id => {
                                const allowed_membership_id = parseInt(authorized_business_membership_id);
                                const allowed_membership = posConfig.business_memberships?.find(row => row.business_membership_id === allowed_membership_id);
                                if(!empty(allowed_membership)){
                                    if(allowed_membership.membership_name === customer_membership_name){
                                        hasRequiredMembership = true;
                                    }
                                }
                            });
                        }

                        if(!hasRequiredMembership){
                            shouldBeFiltered = true;
                        }
                    }
                }

                return isActive && isAvailable && !shouldBeFiltered;
            });
        };

        const globalDiscounts = discountAvailabilityFilter(posDiscounts);

        return (
            <Modal
                title="Apply Global Discount"
                visible={this.props.modalOpened}
                onOk={this.handleModalOk}
                onCancel={this.handleModalCancel}
                cancelText="Cancel"
                okText="Apply"
            >
                <h4 style={{ marginTop: 0 }}>Discount Type</h4>
                <RadioGroup
                    defaultValue={
                        empty(globalDiscountType) ? 'promo' : globalDiscountType
                    }
                    buttonStyle="solid"
                    onChange={this.onChangeDiscountType}
                >
                    {posConfig.auth_role_accesses.ALLOW_PROMO === 1 && (
                        <>
                        <RadioButton value="promo">Promo</RadioButton>
                        </>
                    )}

                    {posConfig.auth_role_accesses.DISCOUNT_TRANSACTION === 1 && (
                        <>
                        <RadioButton value="percentage">Persentase (%)</RadioButton>
                        <RadioButton value="value">Nominal ({currencyCode})</RadioButton>
                        </>
                    )}
                </RadioGroup>

                <br />

                {globalDiscountType === 'percentage' && (
                    <InputNumber
                        min={0}
                        max={100}
                        defaultValue={0}
                        step={0.1}
                        precision={2}
                        placeholder="%"
                        style={{ width: "100%", marginTop: 10 }}
                        onChange={this.onChangeDiscountPercent}
                        value={globalDiscountPercent}
                    />
                )}

                {globalDiscountType === 'value' && (
                    <Input
                        defaultValue={0}
                        type="text"
                        addonBefore={this.currencyCode}
                        size="large"
                        onChange={this.onChangeDiscountNominal}
                        value={globalDiscountNominalText}
                    />
                )}

                {globalDiscountType === 'promo' && posConfig.auth_role_accesses.ALLOW_PROMO === 1 && (
                    <React.Fragment>
                        <h4 style={{ marginTop: 15 }}>Pilih Promo</h4>
                        {empty(globalDiscounts) && (
                            <div>No valid promo.</div>
                        )}
                        {!empty(globalDiscounts) && (
                            <RadioGroup
                                defaultValue={globalDiscountItemId}
                                buttonStyle="solid"
                                onChange={this.onChangeDiscountItemId}
                            >
                                {globalDiscounts.map(discount => {
                                    return (
                                        <RadioButton
                                            value={discount.pos_item_discount_id}
                                            key={discount.pos_item_discount_id}
                                        >
                                            {discount.discount_name}
                                        </RadioButton>
                                    );
                                })}
                            </RadioGroup>
                        )}
                    </React.Fragment>
                )}
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    const currentLocalTrxId = state.posState.posVariables.currentLocalTrxId;
    const currentTrx = state.posState.posTrx.find(
        trx => trx.local_trx_id === currentLocalTrxId
    );
    return {
        currentLocalTrxId,
        currentTrx,
        posDiscounts: state.posState.posDiscounts.filter(
            discount => discount.discount_type === 'basic'
        ),
        globalDiscountType: currentTrx.states.globalDiscountType,
        globalDiscountPercent: currentTrx.states.globalDiscountPercent,
        globalDiscountNominal: currentTrx.states.globalDiscountNominal,
        globalDiscountNominalText: currentTrx.states.globalDiscountNominalText,
        globalDiscountItemId: currentTrx.states.globalDiscountItemId,
        posConfig: state.authState.userData,
        appConfig: state.authState.configs
    };
};

const mapDispatchToProps = {
    storeDiscounts: posOperations.storeDiscounts,
    addTrxGlobalDisc: posOperations.addTrxGlobalDisc,
    setTrxStates: posOperations.setTrxStates,
    setTrxProps: posOperations.setTrxProps
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PosDiscountModal);
