import { Button, Card, Col, Input, message, Row, Spin } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { empty } from "../../shared/helpers/generalHelper";
import { apiCall } from "../../shared/helpers/networkHelper";
import * as endpoint from "../../shared/utils/endpoints";
import { posOperations } from "../../state/ducks/pos";
import styles from "./OrganizationInitiate.module.scss";

class OrganizationInitiate extends Component {
    static contextTypes = {
        router: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.initialState = {
            formLoading: false,
            satusehat_organization_id: ''
        };

        this.state = { ...this.initialState };
    }

    componentDidMount() {
        this.validateOrganizationData();
    }

    validateOrganizationData = () => {
        this.setState({ formLoading: true });

        apiCall(
            endpoint.ERM_ORGANIZATION,
            "get",
            null,
            result => {
                if(!empty(result.data.payload.organizations)){
                    message.error("Tidak dapat menginisialisasi struktur organisasi, karena telah ada sebelumnya.")
                    this.props.history.push("/emr/organization");
                }
                this.setState({ formLoading: false });
            },
            error => {
                if (error === "timeout") {
                    // Network is not available
                    message.error(
                        "Telah terjadi kegagalan koneksi, silakan coba lagi."
                    );
                } else {
                    // API throwing error object
                    message.error(error.data.error.message);
                }
                this.setState({ formLoading: false });
            }
        );
    };

    onSubmit = () => {
        this.setState({ formLoading: true });

        if(empty(this.state.satusehat_organization_id)){
            message.error('ID Organisasi harus diisi.');
            return;
        }

        apiCall(
            endpoint.ERM_ORGANIZATION_INIT,
            "post",
            {
                satusehat_organization_id: this.state.satusehat_organization_id
            },
            result => {
                message.success("Organisasi telah berhasil diinisiasi.");
                this.props.history.push("/emr/organization");
            },
            error => {
                if (error === "timeout") {
                    // Network is not available
                    message.error(
                        "Telah terjadi kegagalan koneksi, silakan coba lagi."
                    );
                } else {
                    // API throwing error object
                    message.error(error.data.error.message);
                }
                this.setState({ formLoading: false });
            }
        );
    };

    render() {
        const {
            formLoading
        } = this.state;

        const pageTitle = (
            <React.Fragment>
                Inisialisasi Struktur Organisasi
            </React.Fragment>
        );

        return (
            <Card title={pageTitle} bordered={false}>
                <Row gutter={48}>
                    <Col xxl={24} sm={24}>
                        <Spin spinning={formLoading}>
                            <div className={styles.formContainer}>
                                <div className={styles.formItem}>
                                    <label>Masukkan ID Organisasi dari Satu Sehat</label>
                                    <Input onChange={(e)=>{
                                        this.setState({
                                            satusehat_organization_id: e.target.value
                                        });
                                    }} value={this.state.satusehat_organization_id} />
                                </div>

                                <div className={styles.formItem}>
                                    <Button className={styles.actionButton}
                                            onClick={this.onSubmit}
                                            type={"primary"}>
                                        Inisiasi Struktur Organisasi
                                    </Button>
                                </div>
                            </div>
                        </Spin>
                    </Col>
                </Row>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        posConfig: state.authState.userData
    };
};

const mapDispatchToProps = {
    setNewLocalTrx: posOperations.setNewLocalTrx
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrganizationInitiate);