import { Button, Col, Empty, List, Modal, Row, Spin, Table, Tabs } from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import React, { useState } from "react";
import uuid from "uuid/v4";
import rmtBack from "../../assets/images/rmt-back.jpg";
import rmtFront from "../../assets/images/rmt-front.jpg";
import rmtSide from "../../assets/images/rmt-side.jpg";
import ErrorHandler from "../../shared/classes/ErrorHandler";
import { apiCall } from "../../shared/helpers/networkHelper";
import * as endpoint from "../../shared/utils/endpoints";

export default function RekamMedisTubuh({ datas, getEmrData, userCode }) {
    const [activeTab, setActiveTab] = useState("front");
    const [modalVisible, setModalVisible] = useState(false);
    const [currentRecord, setCurrentRecord] = useState(null);
    const [noteInput, setNoteInput] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showRmtModal, setShowRmtModal] = useState(false);
    const [formMode, setFormMode] = useState("add");
    const [currentMarks, setCurrentMarks] = useState([]);
    const [selectedRmt, setSelectedRmt] = useState(null);

    // Helper function to get valid datas array
    const getValidDatas = () => {
        if (!datas || datas === "{}" || Object.keys(datas).length === 0) {
            return [];
        }
        return Array.isArray(datas) ? datas : [];
    };

    // Helper function to get next ID
    const getNextId = () => {
        if (currentMarks.length === 0) return 1;
        return Math.max(...currentMarks.map(mark => mark.mark)) + 1;
    };

    const handleImageClick = e => {
        if (formMode === "view") return;

        const rect = e.target.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        const newRecord = {
            mark: getNextId(),
            side: activeTab,
            x: (x / rect.width) * 100,
            y: (y / rect.height) * 100,
            note: ""
        };

        setCurrentRecord(newRecord);
        setModalVisible(true);
    };

    const handleNoteSubmit = () => {
        if (noteInput.trim()) {
            const updatedRecord = {...currentRecord, note: noteInput};

            const updatedMarks = currentMarks.some(mark => mark.mark === currentRecord.mark)
                ? currentMarks.map(mark =>
                    mark.mark === currentRecord.mark ? updatedRecord : mark
                    )
                : [...currentMarks, updatedRecord];

            setCurrentMarks(updatedMarks);

            setModalVisible(false);
            setNoteInput("");
        }
    };

    const handleEditMark = record => {
        setCurrentRecord(record);
        setNoteInput(record.note);
        setModalVisible(true);
    };

    const handleDeleteMark = recordId => {
        const updatedMarks = currentMarks.filter(
            mark => mark.mark !== recordId
        );
        setCurrentMarks(updatedMarks);
    };

    const handleSaveRmt = () => {
        setIsLoading(true);
        const validDatas = getValidDatas();
        let updatedDatas;

        if (formMode === "edit" && selectedRmt) {
            updatedDatas = validDatas.map(record =>
                record.id === selectedRmt.id
                    ? { ...record, data: currentMarks }
                    : record
            );
        } else {
            const newRecord = {
                id: uuid(),
                createdAt: Date.now(),
                data: currentMarks
            };
            updatedDatas = [...validDatas, newRecord];
        }

        apiCall(
            endpoint.GET_CUSTOMER_EMR,
            "put",
            {
                user_code: userCode,
                rekam_medis_tubuh: updatedDatas.length > 0 ? updatedDatas : "{}"
            },
            result => {
                getEmrData(userCode);
                setIsLoading(false);
                setShowRmtModal(false);
                resetForm();
            },
            err => {
                ErrorHandler.handleGeneralError(err);
                setIsLoading(false);
            }
        );
    };

    const handleDeleteRecord = record => {
        setIsLoading(true);
        const validDatas = getValidDatas();
        const updatedDatas = validDatas.filter(item => item.id !== record.id);

        apiCall(
            endpoint.GET_CUSTOMER_EMR,
            "put",
            {
                user_code: userCode,
                rekam_medis_tubuh: updatedDatas.length > 0 ? updatedDatas : "{}"
            },
            result => {
                getEmrData(userCode);
                setIsLoading(false);
            },
            err => {
                ErrorHandler.handleGeneralError(err);
                setIsLoading(false);
            }
        );
    };

    const resetForm = () => {
        setCurrentMarks([]);
        setSelectedRmt(null);
        setActiveTab("front");
    };

    function showDeleteConfirm(recordId) {
        Modal.confirm({
            title: "Yakin ingin menghapus?",
            content: "Data akan dihapus",
            okText: "Hapus",
            okType: "danger",
            cancelText: "Batal",
            onOk() {
                handleDeleteRecord(recordId);
            },
            onCancel() {
                console.log("Cancel");
            }
        });
    }

    const getImageSource = side => {
        switch (side) {
            case "front":
                return rmtFront;
            case "sides":
                return rmtSide;
            case "back":
                return rmtBack;
            default:
                return rmtFront;
        }
    };

    const renderImage = side => (
        <div
            style={{
                overflowX: "auto",
                overflowY: "hidden",
                whiteSpace: "nowrap",
                width: "100%",
                maxWidth: "100vw",
                padding: "10px 0"
            }}
        >
            <div
                style={{
                    position: "relative",
                    width: "550px",
                    height: "500px"
                }}
            >
                <img
                    src={getImageSource(side)}
                    alt={`${side} view`}
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "contain"
                    }}
                    onClick={handleImageClick}
                />
                {currentMarks
                    .filter(mark => mark.side === side)
                    .map(record => (
                        <div
                            key={record.id}
                            style={{
                                position: "absolute",
                                left: `${record.x}%`,
                                top: `${record.y}%`,
                                width: "20px",
                                height: "20px",
                                borderRadius: "50%",
                                background: "rgba(255, 0, 0, 0.5)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "white",
                                fontSize: "12px"
                            }}
                        >
                            {record.mark}
                        </div>
                    ))}
            </div>
        </div>
    );

    const handleRmtModalOpen = (mode, record = null) => {
        console.log(record);
        setFormMode(mode);
        setSelectedRmt(record);
        setCurrentMarks(record ? [...record.data] : []);
        setShowRmtModal(true);
    };

    return (
        <>
            <Button
                style={{ marginBottom: "2rem" }}
                htmlType="button"
                size="default"
                type="primary"
                onClick={() => handleRmtModalOpen("add")}
            >
                Buat Rekam Medis Tubuh
            </Button>
            <h4>Riwayat Rekam Medis Tubuh</h4>
            <Spin spinning={isLoading}>
                {getValidDatas().length > 0 ? (
                    <div style={{ overflowX: "auto" }}>
                        <Table
                            dataSource={getValidDatas().sort((a, b) => b.createdAt - a.createdAt)}
                            rowKey={record => record.id}
                            key={datas.length}
                        >
                            <Table.Column
                                title="Tanggal Input"
                                dataIndex="createdAt"
                                key="createdAt"
                                render={date =>
                                    moment(date).format("YYYY-MM-DD, HH:mm")
                                }
                            />
                            <Table.Column
                                title="Jumlah Data"
                                dataIndex="data"
                                key="markCount"
                                render={data => data.length}
                            />
                            <Table.Column
                                title="Action"
                                key="action"
                                render={(_, record) => (
                                    <>
                                        <Button
                                            size="small"
                                            onClick={() =>
                                                handleRmtModalOpen(
                                                    "view",
                                                    record
                                                )
                                            }
                                            style={{ marginRight: 8 }}
                                        >
                                            Lihat
                                        </Button>
                                        <Button
                                            size="small"
                                            onClick={() =>
                                                handleRmtModalOpen(
                                                    "edit",
                                                    record
                                                )
                                            }
                                            type="primary"
                                            style={{ marginRight: 8 }}
                                        >
                                            Ubah
                                        </Button>
                                        <Button
                                            size="small"
                                            onClick={() =>
                                                showDeleteConfirm(record)
                                            }
                                            type="danger"
                                            style={{ marginRight: 8 }}
                                        >
                                            Hapus
                                        </Button>
                                    </>
                                )}
                            />
                        </Table>
                    </div>
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
            </Spin>

            <Modal
                title={
                    formMode === "add"
                        ? "Add Rekam Medis Tubuh"
                        : formMode === "edit"
                        ? "Edit Rekam Medis Tubuh"
                        : "View Rekam Medis Tubuh"
                }
                visible={showRmtModal}
                onOk={handleSaveRmt}
                onCancel={() => {
                    setShowRmtModal(false);
                    resetForm();
                }}
                width="90%"
                okText={formMode === "add" ? "Simpan" : formMode === "edit" ? "Update" : "Tutup"}
                okButtonProps={{ 
                    disabled: formMode === "view",
                    style: { display: formMode === "view" ? "none" : "inline-block" }
                }}
                maskClosable={false}
            >
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        {formMode !== "view" && <h5>*Klik pada gambar untuk menambahkan catatan</h5>}
                        <Tabs activeKey={activeTab} onChange={setActiveTab}>
                            <Tabs.TabPane tab="Depan" key="front">
                                {renderImage("front")}
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Samping" key="sides">
                                {renderImage("sides")}
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Belakang" key="back">
                                {renderImage("back")}
                            </Tabs.TabPane>
                        </Tabs>
                    </Col>
                    <Col span={12}>
                        <Row gutter={[16, 16]}>
                            {["front", "sides", "back"].map(side => (
                                <List
                                    key={side}
                                    header={
                                        <h3>
                                            {side === "front"
                                                ? "Depan"
                                                : side === "sides"
                                                ? "Samping"
                                                : "Belakang"}
                                        </h3>
                                    }
                                    dataSource={currentMarks.filter(
                                        mark => mark.side === side
                                    )}
                                    renderItem={mark => (
                                        <List.Item key={mark.mark}>
                                            {`${mark.mark}. ${mark.note}`}
                                            {formMode !== "view" && (
                                                <>
                                                    <Button
                                                        type="text"
                                                        icon="edit"
                                                        onClick={() => handleEditMark(mark)}
                                                        style={{ marginLeft: "1em" }}
                                                        size="small"
                                                    />
                                                    <Button
                                                        type="text"
                                                        icon="delete"
                                                        onClick={() => handleDeleteMark(mark.mark)}
                                                        style={{ marginLeft: "1em" }}
                                                        size="small"
                                                    />
                                                </>
                                            )}
                                        </List.Item>
                                    )}
                                />
                            ))}
                        </Row>
                    </Col>
                </Row>
            </Modal>

            <Modal
                title="Enter Description"
                visible={modalVisible}
                onOk={handleNoteSubmit}
                onCancel={() => {
                    setModalVisible(false);
                    setNoteInput("");
                }}
                maskClosable={false}
            >
                <TextArea
                    rows={5}
                    value={noteInput}
                    onChange={e => setNoteInput(e.target.value)}
                    placeholder="Enter note"
                />
            </Modal>
        </>
    );
}
