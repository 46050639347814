import React, { Component } from "react";
import qs from "qs";
import { Icon, Menu, message, Modal } from "antd";
import { authOperations } from "../../state/ducks/auth";
import { connect } from "react-redux";
import imgGDCashierLogo from "../../assets/images/gdcashier-logo-long.png";
import imgGDCashierLogo2x from "../../assets/images/gdcashier-logo-long@2x.png";
import styles from "./SideMenu.module.scss";
import Synchronizer from "../../shared/classes/Synchronizer";
import { posOperations } from "../../state/ducks/pos";
import { apiCall } from "../../shared/helpers/networkHelper";
import * as endpoint from "../../shared/utils/endpoints";
import { withRouter } from "react-router-dom";
import { empty } from "../../shared/helpers/generalHelper";
import { Loader } from "react-overlay-loader";

class SideMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageLoading: false,
            pageLoadingMessage: ""
        };
        this.defaultKey = "";
    }

    showPageLoader = (message = "") => {
        this.setState({
            pageLoading: true,
            pageLoadingMessage: message
        });
    };

    hidePageLoader = () => {
        this.setState({
            pageLoading: false,
            pageLoadingMessage: ""
        });
    };

    doSignOut = async () => {
        this.showPageLoader("Melakukan proses sign out..");
        await Synchronizer.syncPosTrxs(
            this.props.posState,
            this.props.setTrxProps,
            false,
            () => {
                localStorage.setItem("pending_sign_out", "1");
                apiCall(
                    endpoint.GET_AUTH_SIGNOUT,
                    "post",
                    {
                        configs: this.props.configs,
                        deletedTrxs: this.props.posState.deletedTrxs,
                        pendingTrxs: this.props.posState.posTrx.filter(row => {
                            return row.trx_status_id === 2 && (!empty(row.details) || !empty(row.deleted_details));
                        })
                    },
                    response => {
                        message.success("Proses sign out sukses!");
                        localStorage.clear();
                        localStorage.setItem("signed_out", "1");
                        setTimeout(() => {
                            // window.location = "/";
                            this.props.signOut();
                        }, 3000);
                    },
                    response => {
                        message.loading("Gagal melakukan proses sign out, sedang mencoba kembali..");
                        setTimeout(this.doSignOut, 5000);
                    }
                );
            }
        ).catch(err => {
            if (localStorage.getItem("pending_sign_out") !== "1") {
                this.hidePageLoader();
            } else {
                message.loading("Gagal melakukan proses sign out, sedang mencoba kembali..");
                setTimeout(this.doSignOut, 5000);
            }
        });
    };

    handleClick = e => {
        switch (e.key) {
            case "/sign-out":
                let validSignOut = true;
                const that = this;
                const {userData} = this.props;

                if (userData.is_inventory_required === 1) {
                    if (!this.props.posState.posVariables.has_done_inventory_consumption) {
                        validSignOut = false;
                        message.error(`Wajib melakukan konsumsi produk melalui menu "Inventory - Stok Keluar" sebelum sign out.`);
                    }
                }

                if (userData.pos_shift_enforced === 1 && !empty(this.props.activeShift)) {
                    validSignOut = false;
                    message.error(`Shift harus diakhiri terlebih dahulu sebelum sign out.`);
                }

                if(userData.pos_cashier_delete_by_pin === 1 && !empty(this.props.posState?.posTrx?.find(row => row.trx_status_id === 2))){
                    validSignOut = false;
                    message.error(`Seluruh transaksi harus diselesaikan sebelum sign out.`);
                }

                if (validSignOut) {
                    Modal.confirm({
                        title: "Apakah Anda yakin ingin sign out?",
                        content: "Transaksi akan disinkronisasi dan seluruh data transaksi dapat dilihat kembali melalui menu riwayat transaksi",
                        onOk: () => {
                            if (!navigator.onLine) {
                                message.error("Tidak ada koneksi internet! Silakan cek dan coba kembali.");
                            } else {
                                that.doSignOut();
                            }
                        },
                        onCancel: () => {
                        }
                    });
                }

                break;

            default:
                this.props.history.push(e.key);
                break;
        }
    };

    render() {
        const { userData } = this.props;
        const {pageLoading, pageLoadingMessage} = this.state;
        const menuAccess = userData.auth_role_accesses;
        const parsedQS = qs.parse(window.location);
        let openKeys = [];
        this.defaultKey = '/' + parsedQS.pathname.split('/')[1];

        if (
            this.defaultKey === "/redeem-qr-transaction" ||
            this.defaultKey === "/redeem-sms-transaction"
        ) {
            openKeys.push("subRedeem");
        }

        if (
            this.defaultKey === "/inventory/stock" ||
            this.defaultKey === "/inventory/stock-in" ||
            this.defaultKey === "/inventory/stock-out" ||
            this.defaultKey === "/inventory/stock-adjustment" ||
            this.defaultKey === "/inventory/transfer-in" ||
            this.defaultKey === "/inventory/transfer-out" ||
            this.defaultKey === "/inventory/history"
        ) {
            openKeys.push("subInventory");
        }

        if (
            this.defaultKey === "/report/pos-all-trx" ||
            this.defaultKey === "/report/worker-commission" ||
            this.defaultKey === "/report/product-consumption" ||
            this.defaultKey === "/report/coupon" ||
            this.defaultKey === "/report/custom/omzet-recap"
        ) {
            openKeys.push("reporting");
        }

        if (
            this.defaultKey === "/emr"
        ) {
            openKeys.push("subEmr");
        }

        return (
            <div className={styles.wrapper}>
                <Loader fullPage loading={pageLoading} text={pageLoadingMessage}/>

                <div className={styles.logoWrapper}>
                    <img
                        src={imgGDCashierLogo}
                        alt="GD Cashier Logo"
                        srcSet={`${imgGDCashierLogo} 1x, ${imgGDCashierLogo2x} 2x`}
                        className={styles.logo}
                    />
                </div>

                <Menu
                    onClick={this.handleClick}
                    defaultSelectedKeys={[this.defaultKey]}
                    defaultOpenKeys={openKeys}
                    selectedKeys={[this.defaultKey]}
                    mode="inline"
                    className={styles.menu}
                >
                    <Menu.Item key="/">
                        <Icon type="home"/>
                        <span>Home</span>
                    </Menu.Item>

                    {userData.is_getpos === 0 && (
                        <React.Fragment>
                            <Menu.Item key="/loyalty-transaction">
                                <Icon type="shop"/>
                                <span>Pemberian Poin</span>
                            </Menu.Item>

                            <Menu.SubMenu
                                key="subRedeem"
                                title={
                                    <span>
                                        <Icon type="gift"/>
                                        <span>Penukaran Poin</span>
                                    </span>
                                }
                            >
                                <Menu.Item key="/redeem-qr-transaction">
                                    Melalui Kode QR
                                </Menu.Item>
                                <Menu.Item key="/redeem-sms-transaction">
                                    Melalui SMS
                                </Menu.Item>
                            </Menu.SubMenu>
                        </React.Fragment>
                    )}

                    {menuAccess?.CASHIER_SYSTEM === 1 && (
                        <Menu.Item key="/pos">
                            <Icon type="calculator"/>
                            <span>Sistem Kasir</span>
                        </Menu.Item>
                    )}

                    {menuAccess?.APPOINTMENT === 1 && (
                        <Menu.Item key="/appointment">
                            <Icon type="clock-circle"/>
                            <span>Appointment</span>
                        </Menu.Item>
                    )}

                    {menuAccess?.CUSTOMER_INFORMATION === 1 && (
                    <Menu.Item key="/member-info">
                        <Icon type="idcard"/>
                        <span>Informasi Pelanggan</span>
                    </Menu.Item>
                    )}

                    {menuAccess?.CUSTOMER_JOURNAL === 1 && (
                        <Menu.Item key="/customer-journal">
                            <Icon type="snippets"/>
                            <span>Jurnal Pelanggan</span>
                        </Menu.Item>
                    )}
                    
                    {menuAccess?.CUSTOMER_FLOW === 1 && (
                        <Menu.Item key="/customer-registration">
                            <Icon type="solution"/>
                            <span>Registrasi dan Proses</span>
                        </Menu.Item>
                    )}

                    {menuAccess?.MEMBERSHIP_ACTIVATION === 1 && (
                        <Menu.Item key="/member-activation">
                            <Icon type="user-add"/>
                            <span>Aktivasi Membership</span>
                        </Menu.Item>
                    )}

                    {menuAccess?.TRX_HISTORY === 1 && (
                        <Menu.Item key="/trx-history">
                            <Icon type="database"/>
                            <span>Riwayat Transaksi</span>
                        </Menu.Item>
                    )}

                    {menuAccess?.ACCOUNT_RECEIVABLE_LIST === 1 && (
                        <Menu.Item key="/account-receivable">
                            <Icon type="transaction"/>
                            <span>Daftar Piutang</span>
                        </Menu.Item>
                    )}

                    {menuAccess?.SHIFT_MANAGEMENT === 1 && (
                    <Menu.Item key="/shift-management">
                        <Icon type="interaction"/>
                        <span>Manajemen Shift</span>
                    </Menu.Item>
                    )}

                    {menuAccess?.INVENTORY === 1 && (
                        <Menu.SubMenu
                            key="subInventory"
                            title={
                                <span>
                                    <Icon type="gold"/>
                                    <span>Inventory</span>
                                </span>
                            }
                        >
                            {menuAccess?.INV_STOCK_LIST === 1 && (
                            <Menu.Item key="/inventory/stock">
                                Daftar Stok
                            </Menu.Item>
                            )}
                            {menuAccess?.INV_STOCK_IN === 1 && (
                            <Menu.Item key="/inventory/stock-in">
                                Stok Masuk
                            </Menu.Item>
                            )}
                            {menuAccess?.INV_STOCK_OUT === 1 && (
                            <Menu.Item key="/inventory/stock-out">
                                Stok Keluar
                            </Menu.Item>
                            )}
                            {menuAccess?.INV_STOCK_ADJUSTMENT === 1 && (
                                <Menu.Item key="/inventory/stock-adjustment">
                                    Penyesuaian Stok
                                </Menu.Item>
                            )}
                            {menuAccess?.INV_TRANSFER_IN === 1 && (
                            <Menu.Item key="/inventory/transfer-in">
                                Transfer Masuk
                            </Menu.Item>
                            )}
                            {menuAccess?.INV_TRANSFER_OUT === 1 && (
                            <Menu.Item key="/inventory/transfer-out">
                                Transfer Keluar
                            </Menu.Item>
                            )}
                            {/*<Menu.Item key="/inventory/history">
                                Riwayat
                            </Menu.Item>*/}
                        </Menu.SubMenu>
                    )}

                    {menuAccess?.REPORT === 1 && (
                        <Menu.SubMenu
                            key="reporting"
                            title={
                                <span>
                                    <Icon type="book"/>
                                    <span>Laporan</span>
                                </span>
                            }
                        >
                            {menuAccess?.SALES_REPORT === 1 && (
                                <Menu.Item key="/report/pos-all-trx">
                                    Penjualan
                                </Menu.Item>
                            )}

                            {menuAccess?.WORKER_ACTIVITY === 1 && (
                                <Menu.Item key="/report/worker-commission">
                                    Aktivitas Pekerja
                                </Menu.Item>
                            )}

                            {menuAccess?.PRODUCT_CONSUMPTION === 1 && (
                                <Menu.Item key="/report/product-consumption">
                                    Konsumsi Produk
                                </Menu.Item>
                            )}

                            {menuAccess?.EXPIRED_COUPON === 1 && (
                                <Menu.Item key="/report/coupon">
                                    Kupon Expired
                                </Menu.Item>
                            )}

                            {menuAccess?.OMZET_RECAPITULATION === 1 && (
                                <Menu.Item key="/report/custom/omzet-recap">
                                    Rekapitulasi Omset
                                </Menu.Item>
                            )}

                            {menuAccess?.CUSTOMER_BIRTHDAY === 1 && (
                                <Menu.Item key="/report/customer-birthday">
                                    Ulang Tahun Pelanggan
                                </Menu.Item>
                            )}

                            {menuAccess?.TREATMENT_REMINDER === 1 && (
                                <Menu.Item key="/report/treatment-reminder">
                                    Pengingat Treatment
                                </Menu.Item>
                            )}
                        </Menu.SubMenu>
                    )}

                    {/*{menuAccess?.EMR === 1 && (*/}
                        {/*<Menu.SubMenu
                            key="subEmr"
                            title={
                                <span>
                                    <Icon type="medicine-box"/>
                                    <span>EMR</span>
                                </span>
                            }
                        >
                            <Menu.Item key="/emr/organization">
                                Struktur Organisasi
                            </Menu.Item>

                            <Menu.Item key="/emr/location">
                                Lokasi
                            </Menu.Item>
                        </Menu.SubMenu>*/}
                    {/*)}*/}

                    <Menu.Item key="/help">
                        <Icon type="question"/>
                        <span>Bantuan Penggunaan</span>
                    </Menu.Item>

                    <Menu.Item key="/sign-out">
                        <Icon type="logout"/>
                        <span>Sign Out</span>
                    </Menu.Item>
                </Menu>
            </div>
        );
    }
}

const signOut = () => {
    return dispatch => {
        dispatch({
            type: 'USER_SIGNOUT',
        });
    };
};

const mapStateToProps = state => {
    return {
        userData: state.authState.userData,
        posState: state.posState,
        configs: state.authState.configs,
        activeShift: state.authState.shiftManagement.active
    };
};

const mapDispatchToProps = {
    removeUserData: authOperations.removeUserData,
    setTrxProps: posOperations.setTrxProps,
    showGlobalLoader: authOperations.showGlobalLoader,
    hideGlobalLoader: authOperations.hideGlobalLoader,
    setAppStates: authOperations.setAppStates,
    signOut
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SideMenu));
